.contact-cta {
  display: grid;
  justify-items: center;
  grid-gap: $padding-md;

    @include mq(screen-lg) {
      grid-grap: $padding-lg;
    }

    .title {
      color: $color-grey-dark;
    }

}

.contact-cta__links {
  display: grid;
  grid-gap: $padding-sm;
  width: 100%;

  list-style-type: none;

    @include mq(screen-xs) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include mq(screen-sm) {
      max-width: 80%;
      grid-gap: $padding-md;
    }

}

.contact-cta__link {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  padding: $padding-sm;
  
  background-color: $color-grey-light;
  border-radius: $border-radius-md;

  font-family: $interstate;
  line-height: $line-height-sm;
  font-size: calc($font-size-md * $font-mobile-scale);
  letter-spacing: $kerning-sm;
  color: $color-grey-dark;
  text-decoration: none;

  transition: $transition-sm;

    &:hover {
      background-color: $color-grey-dark;

      color: white;
    }

    @include mq(screen-sm) {
      flex-direction: row;
      justify-content: center;

      padding: $padding-md;
    }

    @include mq(screen-lg) {
      font-size: $font-size-md;
    }

}

.contact-cta__link__icon {
  display: grid;
  place-items: center;

  width: 3rem;
  height: 3rem;
  margin: 0 0 0.5rem;

  background-color: $color-orange-dark;
  border-radius: 100%;

    @include mq(screen-sm) {
      margin: 0 $padding-sm 0 0;
    }
    
    svg {
      width: 1.5rem;
      height: 1.5rem;

      fill: white;
    }

}

.contact-cta__location-alert {
  color: $color-grey-mid;
  text-align: center;
  line-height: $line-height-sm;

    &:hover {
      color: $color-orange-dark;
    }

}


// Variants

.bg-grey-dark {
  
  .contact-cta {
    padding: $padding-md;

    background-color: black;
    border-radius: $border-radius-md $border-radius-md 0 0;

      @include mq(screen-sm) {
        padding: $padding-md 0;
      }

      @include mq(screen-lg) {
        padding: $padding-lg 0;
      }

      .title {
        color: white;
      }

      .contact-cta__link {
        background-color: rgba(white, 0.1);

        color: white;

          &:hover {
            background-color: rgba(white, 0.2);
          }

      }

  }


}

.bg-grey-light {
  
  .contact-cta__link {
    background-color: white;

      &:hover {
        background-color: $color-grey-dark;
      }
      
  }


}