.login-form {
  padding: $padding-md;

  background-color: white;
  border-radius: $border-radius-md;
  box-shadow: $box-shadow;
}

.tml-field-wrap + .tml-field-wrap {
  margin: $padding-md 0 0;
}

.tml-label {
  display: block;
  width: 100%;
  margin: 0 0 1rem;

  color: $color-grey-dark;
  line-height: $line-height-md;
}

.tml-field-wrap {
  input[type="text"],
  input[type="number"],
  input[type="url"],
  input[type="password"],
  input[type="email"],
  input[type="tel"],
  input[type="date"],
  select {
    display: block;
    width: 100%;
    height: 6rem;
    padding: 0 1rem;

    border: 2px solid $color-grey-mid;
    border-radius: $border-radius-sm;

    font-size: $font-size-xs;
    font-family: $soleto;

      &:focus {
        outline: none;
        border-color: $color-orange-dark;
      }

  }

}


.tml-rememberme-wrap {
  margin: $padding-md 0 0;

    label {
      display: inline;
      margin: 0;
    }

}

.tml-button {
  @extend .btn-lg;
  @extend .btn--orange;  
}

.tml-login .tml-alerts,
.tml-lostpassword .tml-alerts {
  margin: 0 0 $padding-md;
}

.tml-errors {
  padding: $padding-sm;
  list-style-type: none;

  background-color: $color-error;
  border-radius: $border-radius-sm;
}

.tml-error {
  color: white;
}

.tml-error + .tml-error {
  margin: 1rem 0 0;
}

.tml-links {
  display: flex;
  flex-wrap: wrap;
  gap: $padding-sm;
  margin: $padding-lg 0 0;

  font-size: $font-size-xxs;
  list-style-type: none;

    a {
      color: $color-grey-dark;
    }

    a:hover {
      color: $color-orange-dark;
    }

}

.no-flexbox-gap {

  .tml-links li + .tml-links li {
    margin: $padding-sm 0 0;
  }

}

.tml-messages {
  padding: $padding-sm;
  list-style-type: none;

  background-color: $color-success;
  border-radius: $border-radius-sm;
}

.tml-message {
  color: white;

    a {
      color: inherit;
    }

}

.tml-message + .tml-message {
  margin: 1rem 0 0;
}

.tml-register-link {
  display: none;
}
