.text-hero-outer {
  position: relative;
  padding: $padding-lg 0;

  background-color: white;
  box-shadow: $box-shadow;

  overflow: hidden;

    @include mq(screen-sm) {
      padding: $padding-xl 0;
    }

    .inner {
      position: relative;
      z-index: 2;
    }

    &:before {
      content: "";
  
      position: absolute;
      bottom: -20vw;
      right: -40vw;
      z-index: 1;
  
      width: 60vw;
      padding-bottom: 60vw;
  
      background: $gradient-orange-h;
      border-radius: 100%;
    }

}

.text-hero {
  display: grid;
  grid-gap: $padding-md;
  justify-items: center;

    @include mq(screen-lg) {
      grid-gap: $padding-lg;
    }

    .title,
    .supporting-text {
      text-align: center;
    }

    .btn-group {
      justify-content: center;
    }

}