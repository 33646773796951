.vehicle-logos {
  display: flex;
  gap: $padding-lg;
  flex-wrap: wrap;
  justify-content: center;
  list-style-type: none;

  @include flexbox-gap-support(5);

    @include mq(screen-lg) {
      gap: $padding-xl;
    }

}