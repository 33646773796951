// Images

@mixin flex-img {
	display: block;
	width: 100%;
	height: auto;
    
    @include ie-11-support {
      flex-shrink: 0;
    }
}

@mixin object-fit {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

// Aspect ratio

@mixin aspect-ratio($val1, $val2) {
  aspect-ratio: #{$val1} / #{$val2};

  @supports not (aspect-ratio: #{$val1} / #{$val2}) {
    &:before {
      float: left;
      padding-top: ($val2 / $val1) * 100%;
      content: "";
    }

    &:after {
      display: block;
      content: "";
      clear: both;
    }
  }
}

// IE Support

@mixin ie-full-support {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {@content;}
	@supports (-ms-accelerator:true) {@content;}
}

@mixin ie-11-support {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {@content;}
}

@mixin ie-edge-support {
	@supports (-ms-accelerator:true) {@content;}
}


// FLEXBOX GAP

@mixin flexbox-gap-support($gap) {
  
  .no-flexbox-gap & {
    margin: -($gap/2) + rem;

      > * {
        padding: $gap/2 + rem;

      }

  }

}

// TYPE

@mixin fluid-type($min, $max) {
  $minValue: $min + rem;
  $maxFinalValue: $max * 10;
  $midValue: ($maxFinalValue / 1280) * 100vw;
  $maxValue: $max + rem;
  font-size: $maxValue;
  font-size: clamp($minValue, $midValue, $maxValue);
}

@mixin big-fluid-type($min, $max) {
  $minValue: $min + rem;
  $maxFinalValue: $max * 10;
  $midValue: ($maxFinalValue / 1600) * 100vw;
  $maxValue: $max + rem;
  font-size: $maxValue;
  font-size: clamp($minValue, $midValue, $maxValue);
}












