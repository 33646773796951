.hero-contact-cta__links {
  display: grid;
  grid-gap: $padding-sm;
  width: 100%;

  list-style-type: none;

    @include mq(screen-md) {
      grid-template-columns: 1fr 1fr;
    }

}

.hero-contact-cta__link {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  padding: $padding-sm;
  
  background-color: $color-grey-light;
  border-radius: $border-radius-md;

  font-family: $interstate;
  line-height: $line-height-sm;
  font-size: $font-size-xs;
  letter-spacing: $kerning-sm;
  color: $color-grey-dark;
  text-decoration: none;

  transition: $transition-sm;

    @include mq(screen-lg) {
      flex-direction: row;
      justify-content: center;
    }

    &:hover {
      background-color: $color-grey-dark;

      color: white;
    }

}

.hero-contact-cta__link__icon {
  display: grid;
  place-items: center;

  width: 2rem;
  height: 2rem;

  background-color: $color-orange-dark;
  border-radius: 100%;

    @include mq(screen-lg) {
      margin: 0 1rem 0 0;
    }

    svg {
      width: 1rem;
      height: 1rem;

      fill: white;
    }

}