.manuals-header {
  padding: 0 0 $padding-md;
  margin: 0 0 $padding-md;

  border-bottom: 1px solid white;

    @include mq(screen-sm) {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .title {
      margin: 0 0 $padding-sm;

      @include mq(screen-sm) {
        margin: 0;
      }

    }

    .site-search {
      background-color: white;

      @include mq(screen-sm) {
        width: 30rem;
      }

    }

}

.manual-filters {
  padding: 0 0 $padding-md;
  margin: 0 0 $padding-md;

  border-bottom: 1px solid white;

    @include mq(screen-sm) {
      display: flex;
      align-items: center;
    }

    @include mq(screen-lg) {
      margin: 0 0 $padding-lg;
    }
    
    .title {
      margin: 0 0 $padding-sm;

        @include mq(screen-sm) {
          margin: 0 $padding-sm 0 0;
        }

    }

}

.manuals-feed {
  display: grid;
  grid-gap: $padding-lg;

  list-style-type: none;
  
    @include mq(screen-xs) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include mq(screen-lg) {
      grid-gap: $padding-xl;
    }

}

.manual-card {
  
  @include mq(screen-sm) {
    display: grid;
    grid-gap: $padding-md;
    grid-template-columns: 1fr 4fr;
  }

}

.manual-card__img-outer {
  display: none;

    @include mq(screen-xs) {
      display: block;
      height: 15rem;
      margin: 0 0 $padding-md;
    }

    @include mq(screen-sm) {
      height: auto;
      margin: 0;
    }

}

.manual-card__img {
  display: block;
  width: auto;
  height: 100%;

    @include mq(screen-sm) {
      width: 100%;
      height: auto;
    }

}

.manual-card__info {
  position: relative;
  padding: 0 0 $padding-lg;

    @include mq(screen-sm) {
      padding: 0.25rem 0 $padding-lg;
    }

    > .btn-xs {
      position: absolute;
      bottom: 0.25rem;
      left: 0;
      z-index: 1;
    }

}