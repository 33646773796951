.testimonials .tns-outer {
  background-color: white;
  border-radius: $border-radius-md;
  box-shadow: $box-shadow;
}

.testimonial {

  @include mq(screen-sm) {
    vertical-align: middle !important;
  }

}

.testimonial__content {
  display: grid;
  grid-gap: $padding-md;
  padding: $padding-md;

    @include mq(screen-sm) {
      grid-template-columns: 10rem 1fr;
      padding: $padding-lg $padding-xl;
    }

    @include mq(screen-lg) {
      grid-template-columns: 15rem 1fr;
      grid-gap: $padding-xl;
    }

}

.testimonial__logo {
  display: block;
  width: 10rem;
  height: auto;

    @include mq(screen-lg) {
      width: 15rem;
    }
}

.testimonial__quote {
  margin: 0 0 $padding-sm;

  font-family: $interstate;
  font-size: $font-size-xs;
  color: $color-orange-dark;
  line-height: $line-height-sm;
  letter-spacing: $kerning-sm;

    @include mq(screen-lg) {
      font-size: $font-size-sm;
    }

    &:before {
      content: open-quote;
    }

    &:after {
      content: close-quote;
    }

}