.specification__header {

  @include mq(screen-xs) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
    .btn-sm {
      margin: $padding-md 0 0;

        @include mq(screen-xs) {
          margin: 0;
        }

    }

    
}

.specification-list {
  display: grid;
  grid-gap: $padding-md;

  list-style-type: none;

    @include mq(screen-xs) {
      grid-template-columns: 1fr 1fr;
    }
}

.specification-list__item {
  padding: $padding-md;

  background-color: $color-grey-light;
  border-radius: $border-radius-md;
}