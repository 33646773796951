.text-img {
  display: grid;
  grid-gap: $padding-md;

    @include mq(screen-xs) {
      align-items: center;
    }

    @include mq(screen-md) {
      //align-items: start;
      //align-items: center;
      grid-template-columns: 1fr 1fr;
      grid-gap: $padding-xl;
    }

    &.is-aligned {
      
      @include mq(screen-xs) {
        align-items: start;
      }

    }

}

.text-img--default {
  
  @include mq(screen-xs) {
    grid-template-columns: 1fr 2fr;
  }

}

.text-img--alt {
  
  @include mq(screen-xs) {
    grid-template-columns: 2fr 1fr;
  }

  .text-img__text {
    
    @include mq(screen-sm) {
      order: 1;
    }

  }

  .text-img__img-outer {
    
    @include mq(screen-xs) {
      order: 2;
    }

  }

}

.text-img__img {
  border-radius: $border-radius-md;

  @include flex-img;
}

.text-img__text {
  
  @include mq(screen-md) {
    padding: 0.5rem 0 0;
  }

}

.text-img__caption {
  margin: $padding-sm 0 0;

  font-size: $font-size-xs;
  color: $color-orange-dark;
}