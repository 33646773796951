.hero-cta {
  display: grid;
  grid-gap: $padding-md;
  padding: $padding-md;
  margin: $padding-md 0 0;

  background-color: white;
  border-radius: $border-radius-md;
  box-shadow: $box-shadow;

    @include mq(screen-lg) {
      margin: $padding-lg 0 0;
    }

}