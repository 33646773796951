.pg-header {
  position: relative;
  z-index: 2;
  
  padding: 0 $padding-md;

  background-color: white;
  box-shadow: $box-shadow;

    @include mq(screen-lg) {
      padding: 0 $padding-lg;
    }

}

// Utilities

.utilities,
.main-nav-outer {
  
  @include mq(screen-xl) {
    max-width: $screen-xl;
    margin: 0 auto;
  }

}

.utilities {
  display: flex;
  justify-content: space-between;
  padding: $padding-sm 0;

  border-bottom: 1px solid $color-grey-light;
}

.location-switcher__toggle {
  display: flex;
  align-items: center;
  padding: 0 1rem;
  height: 3rem;

  background-color: $color-grey-light;
  border-radius: $border-radius-sm;

  font-size: $font-size-xs;
    
    > svg {
      width: 1rem;
      transform: rotate(90deg);

      fill: $color-grey-dark;
    }

  &.is-selected svg {
    transform: rotate(270deg);
  }

}

.location-switcher__flag {
  width: 2rem;
  height: 1.2rem;
  flex-shrink: 0;
  margin: 0 1rem 0 0;

  background-color: white;
  border: 1px solid black;
}

.location-switcher__label {
  display: none;
  
    @include mq(screen-sm) {
      display: block;
      margin: 0 1rem 0 0;
    }

}

.location-switcher-list {
  display: none;

  &.is-visible {
    display: block;
  }
}

.tel-login {
  display: flex;
  align-items: center;
}

.tel-cta {
  display: flex;
  align-items: center;
  margin: 0 $padding-md 0 0;

  text-decoration: none;
  color: $color-grey-dark;
  
    svg {
      width: 1.5rem;
      height: 1.5rem;
      margin: 0 1rem 0 0;

      fill: currentColor
    }

}

.tel-cta__label,
.tel-cta__mob-label {
  font-size: $font-size-xs;
}

.tel-cta__label {
  display: none;

    @include mq(screen-sm) {
      display: block;
    }
}

.tel-cta__mob-label {
  
  @include mq(screen-sm) {
    display: none;
  }

}

.login-cta {
  display: flex;
  align-items: center;

  font-size: $font-size-xs;
  text-decoration: none;
  color: $color-grey-dark;

    @include mq(screen-sm) {
      height: 3rem;
      padding: 0 1.5rem;

      border-radius: 1.5rem;
      background-color: $color-orange-dark;

      color: white;
    }
  
    svg {
      width: 1.5rem;
      height: 1.5rem;
      margin: 0 1rem 0 0;

      fill: $color-orange-dark;

        @include mq(screen-sm) {
          fill: white;
        }

    }

    &:hover {

      @include mq(screen-sm) {
        background-color: $color-grey-dark;
      }

    }

}


// Main nav

.main-nav-outer {
  position: relative;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $padding-sm 0;

    @include mq(screen-lg) {
      padding: $padding-md 0;
    }
}

.logo {
  display: block;
  height: 2.5rem;

    @include mq(screen-lg) {
      height: 3rem;
    }

    svg {
      width: auto;
      height: 100%;
    }

}

.main-nav-toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 4rem;
  padding: 0 1.5rem;

  font-size: $font-size-xs;
  color: white;

  background-color: $color-orange-dark;
  border-radius: $border-radius-sm;

    @include mq(screen-xs) {
      width: auto;
    }

    @include mq(screen-lg) {
      display: none;
    }

    svg {
      width: 2rem;
      height: 2rem;
      margin: 0 0 0 $padding-md;

      fill: currentColor;
    }

    &.is-selected {
      background-color: $color-grey-dark;
    }

}

.main-nav {
  display: none;

  position: absolute;
  top: 6.5rem;
  right: 0;
  z-index: 1;

  width: 100%;
  padding: 10rem $padding-md $padding-md;

  border-radius: $border-radius-sm;
  background-color: $color-orange-dark;

    @include mq(screen-sm) {
      width: 30rem;
      padding: $padding-md;
    }

    @include mq(screen-lg) {
      top: 0;
      z-index: 3;

      display: block;
      width: auto;
      padding: 0;
      
      border-radius: 0;
      background: none;
    }

    @include mq(screen-xl) {
      width: 60%;
    }

    &.is-visible {
      display: block;
    }

}

.main-nav__list {
  list-style-type: none;

    @include mq(screen-lg) {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: 8rem;
    }
    
    li + li {
      margin: $padding-sm 0 0; 
    }

    & > li + li {
      
      // @include mq(screen-lg) {
      //   margin: 0 0 0 4vw;
      // }

      // @include mq(screen-xl) {
      //   margin: 0 0 0 $padding-xl;
      // }

      @include mq(screen-lg) {
        margin: 0;
      }

    }

    & > li {

      @include mq(screen-lg) {
        display: flex;
        align-items: center;
        height: 8rem;
      }

      > a {
        
        @include mq(screen-lg) {
          padding: 0 0 0 2vw;
        }

        @include mq(screen-xl) {
          padding: 0 0 0 3vw;
        }

      }
    }

    & > li:hover > a {
      
      @include mq(screen-lg) {
        color: $color-orange-dark;
      }

    }

    & > li:hover > .sub-menu {
      
      @include mq(screen-lg) {
        display: block;
      }

    }
    
    a {
      display: block;
      width: 100%;

      font-size: $font-size-xs;
      color: white;
      text-decoration: none;

        @include mq(screen-lg) {
          //font-size: $font-size-sm;
        }

    }

    & > li > a {
      @include mq(screen-lg) {
        color: $color-grey-dark;

          &:hover {
            color: $color-orange-dark;
          }

      }

    }

    li.menu-item-has-children {
      position: relative;
    }

    li.menu-item-has-children > a {
      position: relative;

        &:after {
          content: "";

          position: absolute;
          top: 50%;
          right: 0.5rem;
          width: 0;
          height: 0;
          z-index: 1;
          
          border-style: solid;
          border-width: 5px 5px 0 5px;
          border-color: white transparent transparent transparent;

            @include mq(screen-lg) {
              display: none;
            }

        }

    }

    li.menu-item-has-children a.is-active {
      color: $color-grey-dark;
    }

    li.menu-item-has-children a.is-active:after {
      border-color: $color-grey-dark transparent transparent transparent;
    }

    li.menu-item-has-children .sub-menu.is-open {
      display: block;
    }

    li.menu-item-has-children .sub-menu {
      display: none;
    }

    .sub-menu {
      padding: 0 0 0 $padding-sm;
      margin: $padding-sm 0 0;

      list-style-type: none;
      border-left: 5px solid rgba(white, 0.1);

        @include mq(screen-lg) {
          position: absolute;
          top: 7rem;
          right: -1rem;
          z-index: 1;

          width: 20rem;
          padding: $padding-md;
          margin: 0;

          border: none;
          border-radius: $border-radius-md;

          background-color: $color-orange-dark;
        }

        a:hover {
          
          @include mq(screen-lg) {
            color: $color-grey-dark;
          }

        }


    }


}

// Portal specific styles

.pg-header--portal {
  
  .tel-login {
    width: 100%;
    justify-content: space-between;
  }

}
