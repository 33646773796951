/*!
Theme Name: MiniCam
Theme URI: 
Author: Ride Shotgun
Author URI: http://www.rideshotgun.co.uk
Description: 
Version: 2.0
License: GNU General Public License v2 or later
License URI: http://www.gnu.org/licenses/gpl-2.0.html
Text Domain: 
Tags:
*/
html,
body {
  border: none;
  margin: 0;
  padding: 0;
}

body {
  position: relative;
  font-family: soleto, Arial, sans-serif;
  font-style: normal;
  font-size: 1.35rem;
  color: #76868b;
  background-color: white;
}
@media (min-width: 1280px) {
  body {
    font-size: 1.8rem;
  }
}

[id] {
  scroll-margin-top: 2rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  line-height: 1.2;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
address,
big,
cite,
code,
em,
font,
img,
small,
strike,
sub,
sup,
li,
ol,
ul,
fieldset,
form,
label,
legend,
button,
table,
caption,
tr,
th,
td {
  border: none;
  font-weight: inherit;
  line-height: inherit;
  margin: 0;
  padding: 0;
  text-align: inherit;
}

p {
  line-height: 1.5;
}

strong {
  font-weight: normal;
}

blockquote::before,
blockquote::after {
  content: "";
}

html {
  font-size: 62.5%;
  /* 1rem = 10px */
  scroll-behavior: smooth;
}

@media (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }

  * {
    animation-duration: 0s !important;
    transition-duration: 0s !important;
  }
}
*,
*::before,
*::after {
  box-sizing: inherit;
  -webkit-font-smoothing: antialiased;
  word-break: break-word;
  word-wrap: break-word;
}

input[type=text],
input[type=password],
input[type=email],
input[type=url],
input[type=date],
input[type=month],
input[type=time],
input[type=datetime],
input[type=datetime-local],
input[type=week],
input[type=number],
input[type=search],
input[type=tel],
input[type=color],
input[type=submit],
textarea,
button {
  font-family: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: none;
}

select {
  font-size: inherit;
}

button, input[type=submit] {
  cursor: pointer;
}

.no-scroll {
  overflow: hidden;
}

* {
  box-sizing: border-box;
}

.screen-reader-text {
  display: none;
}

.align-center {
  text-align: center;
}

.bg-white {
  background-color: white;
}

.bg-grey-dark {
  background-color: #1d242b;
}

.bg-grey-mid {
  background-color: #76868b;
}

.bg-grey-light, .main-content--portal .product .pg-content__section:nth-child(odd) > div {
  background-color: #f5f5f5;
}

.bg-black {
  background-color: black;
}

.bg-orange-dark {
  background-color: #ef7d43;
}

.bg-orange-light {
  background-color: #fab500;
}

.bg-orange-gradient {
  background: linear-gradient(90deg, #fab500 0%, #ef7d43 100%);
}

.video-embed {
  aspect-ratio: 16/9;
}
@supports not (aspect-ratio: 16/9) {
  .video-embed:before {
    float: left;
    padding-top: 56.25%;
    content: "";
  }
  .video-embed:after {
    display: block;
    content: "";
    clear: both;
  }
}

.video-embed iframe {
  width: 100%;
  height: 100%;
}

.flex-img {
  display: block;
  width: 100%;
  height: auto;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .flex-img {
    flex-shrink: 0;
  }
}

.outer {
  position: relative;
}

.inner {
  width: 100%;
  margin: 0 auto;
  padding: 0 2.5rem;
}
@media (min-width: 1280px) {
  .inner {
    padding: 0 5rem;
  }
}

.inner.inner--full {
  padding: 0;
}

@media (min-width: 768px) {
  .inner--sm {
    width: 768px;
  }
}

@media (min-width: 1024px) {
  .inner--md {
    width: 1024px;
  }
}

@media (min-width: 1280px) {
  .inner--lg {
    width: 1280px;
  }
}

@media (min-width: 1600px) {
  .inner--xl {
    width: 1600px;
  }
}

@media (min-width: 1800px) {
  .inner--xxl {
    width: 1800px;
  }
}

.main-content {
  position: relative;
  z-index: 1;
  overflow-x: hidden;
}

.main-content--portal {
  min-height: 75vh;
}

.pg-content__section:not(:first-child):not(:last-child):not(:only-child) {
  margin: 5rem 0;
}
@media (min-width: 1280px) {
  .pg-content__section:not(:first-child):not(:last-child):not(:only-child) {
    margin: 10rem 0;
  }
}

.pg-content__section:only-child > .bg-white,
.pg-content__section:first-child:not(:only-child) > .bg-white,
.pg-content__section + .pg-content__section:last-child > .bg-white {
  padding: 5rem 0;
}
@media (min-width: 1280px) {
  .pg-content__section:only-child > .bg-white,
.pg-content__section:first-child:not(:only-child) > .bg-white,
.pg-content__section + .pg-content__section:last-child > .bg-white {
    padding: 10rem 0;
  }
}

.pg-content__section:last-child .pg-end-ctas,
.pg-content__section:only-child .pg-end-ctas {
  padding: 0 0 5rem;
}
@media (min-width: 1280px) {
  .pg-content__section:last-child .pg-end-ctas,
.pg-content__section:only-child .pg-end-ctas {
    padding: 0 0 10rem;
  }
}

.mt-xs {
  margin-top: 0.5rem;
}
@media (min-width: 1024px) {
  .mt-xs {
    margin-top: 1rem;
  }
}

.mt-sm {
  margin-top: 1rem;
}
@media (min-width: 1024px) {
  .mt-sm {
    margin-top: 1.6666666667rem;
  }
}

.mt-md, .place-order, .product-info .cart {
  margin-top: 1.6666666667rem;
}
@media (min-width: 1024px) {
  .mt-md, .place-order, .product-info .cart {
    margin-top: 2.5rem;
  }
}

.mt-lg {
  margin-top: 2.5rem;
}
@media (min-width: 1024px) {
  .mt-lg {
    margin-top: 5rem;
  }
}

.mt-xl {
  margin-top: 5rem;
}
@media (min-width: 1024px) {
  .mt-xl {
    margin-top: 10rem;
  }
}

.mb-xs {
  margin-bottom: 0.5rem;
}
@media (min-width: 1024px) {
  .mb-xs {
    margin-bottom: 1rem;
  }
}

.mb-sm, .woocommerce-order-details__title {
  margin-bottom: 1rem;
}
@media (min-width: 1024px) {
  .mb-sm, .woocommerce-order-details__title {
    margin-bottom: 1.6666666667rem;
  }
}

.mb-md, .woocommerce-privacy-policy-text, .woocommerce-checkout-review-order > table,
.woocommerce-table--order-details, .woocommerce-checkout > h3, .cart-collaterals h2, .product .related > h2, .product-info .price {
  margin-bottom: 1.6666666667rem;
}
@media (min-width: 1024px) {
  .mb-md, .woocommerce-privacy-policy-text, .woocommerce-checkout-review-order > table,
.woocommerce-table--order-details, .woocommerce-checkout > h3, .cart-collaterals h2, .product .related > h2, .product-info .price {
    margin-bottom: 2.5rem;
  }
}

.mb-lg, .product-info .cart {
  margin-bottom: 2.5rem;
}
@media (min-width: 1024px) {
  .mb-lg, .product-info .cart {
    margin-bottom: 5rem;
  }
}

.mb-xl {
  margin-bottom: 5rem;
}
@media (min-width: 1024px) {
  .mb-xl {
    margin-bottom: 10rem;
  }
}

.mtb-xs {
  margin: 0.5rem 0;
}
@media (min-width: 1024px) {
  .mtb-xs {
    margin: 1rem 0;
  }
}

.mtb-sm {
  margin: 1rem 0;
}
@media (min-width: 1024px) {
  .mtb-sm {
    margin: 1.6666666667rem 0;
  }
}

.mtb-md {
  margin: 1.6666666667rem 0;
}
@media (min-width: 1024px) {
  .mtb-md {
    margin: 2.5rem 0;
  }
}

.mtb-lg {
  margin: 2.5rem 0;
}
@media (min-width: 1024px) {
  .mtb-lg {
    margin: 5rem 0;
  }
}

.mtb-xl {
  margin: 5rem 0;
}
@media (min-width: 1024px) {
  .mtb-xl {
    margin: 10rem 0;
  }
}

.pt-xs {
  padding-top: 0.5rem;
}
@media (min-width: 1024px) {
  .pt-xs {
    padding-top: 1rem;
  }
}

.pt-sm {
  padding-top: 1rem;
}
@media (min-width: 1024px) {
  .pt-sm {
    padding-top: 1.6666666667rem;
  }
}

.pt-md {
  padding-top: 1.6666666667rem;
}
@media (min-width: 1024px) {
  .pt-md {
    padding-top: 2.5rem;
  }
}

.pt-lg {
  padding-top: 2.5rem;
}
@media (min-width: 1024px) {
  .pt-lg {
    padding-top: 5rem;
  }
}

.pt-xl {
  padding-top: 5rem;
}
@media (min-width: 1024px) {
  .pt-xl {
    padding-top: 10rem;
  }
}

.pt-xxl {
  padding-top: 10rem;
}
@media (min-width: 1024px) {
  .pt-xxl {
    padding-top: 15rem;
  }
}

.pb-xs {
  padding-bottom: 0.5rem;
}
@media (min-width: 1024px) {
  .pb-xs {
    padding-bottom: 1rem;
  }
}

.pb-sm {
  padding-bottom: 1rem;
}
@media (min-width: 1024px) {
  .pb-sm {
    padding-bottom: 1.6666666667rem;
  }
}

.pb-md {
  padding-bottom: 1.6666666667rem;
}
@media (min-width: 1024px) {
  .pb-md {
    padding-bottom: 2.5rem;
  }
}

.pb-lg {
  padding-bottom: 2.5rem;
}
@media (min-width: 1024px) {
  .pb-lg {
    padding-bottom: 5rem;
  }
}

.pb-xl {
  padding-bottom: 5rem;
}
@media (min-width: 1024px) {
  .pb-xl {
    padding-bottom: 10rem;
  }
}

.ptb-xs {
  padding: 0.5rem 0;
}
@media (min-width: 1024px) {
  .ptb-xs {
    padding: 1rem 0;
  }
}

.ptb-sm {
  padding: 1rem 0;
}
@media (min-width: 1024px) {
  .ptb-sm {
    padding: 1.6666666667rem 0;
  }
}

.ptb-md {
  padding: 1.6666666667rem 0;
}
@media (min-width: 1024px) {
  .ptb-md {
    padding: 2.5rem 0;
  }
}

.ptb-lg {
  padding: 2.5rem 0;
}
@media (min-width: 1024px) {
  .ptb-lg {
    padding: 5rem 0;
  }
}

.ptb-xl, .main-content--portal .product .pg-content__section:nth-child(odd) > div {
  padding: 5rem 0;
}
@media (min-width: 1024px) {
  .ptb-xl, .main-content--portal .product .pg-content__section:nth-child(odd) > div {
    padding: 10rem 0;
  }
}

.ptb-xxl {
  padding: 10rem 0;
}
@media (min-width: 1024px) {
  .ptb-xxl {
    padding: 15rem 0;
  }
}

.pg-header {
  position: relative;
  z-index: 2;
  padding: 0 2.5rem;
  background-color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
@media (min-width: 1280px) {
  .pg-header {
    padding: 0 5rem;
  }
}

@media (min-width: 1600px) {
  .utilities,
.main-nav-outer {
    max-width: 1600px;
    margin: 0 auto;
  }
}

.utilities {
  display: flex;
  justify-content: space-between;
  padding: 1.6666666667rem 0;
  border-bottom: 1px solid #f5f5f5;
}

.location-switcher__toggle {
  display: flex;
  align-items: center;
  padding: 0 1rem;
  height: 3rem;
  background-color: #f5f5f5;
  border-radius: 0.5rem;
  font-size: 1.8rem;
}
.location-switcher__toggle > svg {
  width: 1rem;
  transform: rotate(90deg);
  fill: #1d242b;
}
.location-switcher__toggle.is-selected svg {
  transform: rotate(270deg);
}

.location-switcher__flag {
  width: 2rem;
  height: 1.2rem;
  flex-shrink: 0;
  margin: 0 1rem 0 0;
  background-color: white;
  border: 1px solid black;
}

.location-switcher__label {
  display: none;
}
@media (min-width: 768px) {
  .location-switcher__label {
    display: block;
    margin: 0 1rem 0 0;
  }
}

.location-switcher-list {
  display: none;
}
.location-switcher-list.is-visible {
  display: block;
}

.tel-login {
  display: flex;
  align-items: center;
}

.tel-cta {
  display: flex;
  align-items: center;
  margin: 0 2.5rem 0 0;
  text-decoration: none;
  color: #1d242b;
}
.tel-cta svg {
  width: 1.5rem;
  height: 1.5rem;
  margin: 0 1rem 0 0;
  fill: currentColor;
}

.tel-cta__label,
.tel-cta__mob-label {
  font-size: 1.8rem;
}

.tel-cta__label {
  display: none;
}
@media (min-width: 768px) {
  .tel-cta__label {
    display: block;
  }
}

@media (min-width: 768px) {
  .tel-cta__mob-label {
    display: none;
  }
}

.login-cta {
  display: flex;
  align-items: center;
  font-size: 1.8rem;
  text-decoration: none;
  color: #1d242b;
}
@media (min-width: 768px) {
  .login-cta {
    height: 3rem;
    padding: 0 1.5rem;
    border-radius: 1.5rem;
    background-color: #ef7d43;
    color: white;
  }
}
.login-cta svg {
  width: 1.5rem;
  height: 1.5rem;
  margin: 0 1rem 0 0;
  fill: #ef7d43;
}
@media (min-width: 768px) {
  .login-cta svg {
    fill: white;
  }
}
@media (min-width: 768px) {
  .login-cta:hover {
    background-color: #1d242b;
  }
}

.main-nav-outer {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.6666666667rem 0;
}
@media (min-width: 1280px) {
  .main-nav-outer {
    padding: 2.5rem 0;
  }
}

.logo {
  display: block;
  height: 2.5rem;
}
@media (min-width: 1280px) {
  .logo {
    height: 3rem;
  }
}
.logo svg {
  width: auto;
  height: 100%;
}

.main-nav-toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 4rem;
  padding: 0 1.5rem;
  font-size: 1.8rem;
  color: white;
  background-color: #ef7d43;
  border-radius: 0.5rem;
}
@media (min-width: 650px) {
  .main-nav-toggle {
    width: auto;
  }
}
@media (min-width: 1280px) {
  .main-nav-toggle {
    display: none;
  }
}
.main-nav-toggle svg {
  width: 2rem;
  height: 2rem;
  margin: 0 0 0 2.5rem;
  fill: currentColor;
}
.main-nav-toggle.is-selected {
  background-color: #1d242b;
}

.main-nav {
  display: none;
  position: absolute;
  top: 6.5rem;
  right: 0;
  z-index: 1;
  width: 100%;
  padding: 10rem 2.5rem 2.5rem;
  border-radius: 0.5rem;
  background-color: #ef7d43;
}
@media (min-width: 768px) {
  .main-nav {
    width: 30rem;
    padding: 2.5rem;
  }
}
@media (min-width: 1280px) {
  .main-nav {
    top: 0;
    z-index: 3;
    display: block;
    width: auto;
    padding: 0;
    border-radius: 0;
    background: none;
  }
}
@media (min-width: 1600px) {
  .main-nav {
    width: 60%;
  }
}
.main-nav.is-visible {
  display: block;
}

.main-nav__list {
  list-style-type: none;
}
@media (min-width: 1280px) {
  .main-nav__list {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 8rem;
  }
}
.main-nav__list li + li {
  margin: 1.6666666667rem 0 0;
}
@media (min-width: 1280px) {
  .main-nav__list > li + li {
    margin: 0;
  }
}
@media (min-width: 1280px) {
  .main-nav__list > li {
    display: flex;
    align-items: center;
    height: 8rem;
  }
}
@media (min-width: 1280px) {
  .main-nav__list > li > a {
    padding: 0 0 0 2vw;
  }
}
@media (min-width: 1600px) {
  .main-nav__list > li > a {
    padding: 0 0 0 3vw;
  }
}
@media (min-width: 1280px) {
  .main-nav__list > li:hover > a {
    color: #ef7d43;
  }
}
@media (min-width: 1280px) {
  .main-nav__list > li:hover > .sub-menu {
    display: block;
  }
}
.main-nav__list a {
  display: block;
  width: 100%;
  font-size: 1.8rem;
  color: white;
  text-decoration: none;
}
@media (min-width: 1280px) {
  .main-nav__list > li > a {
    color: #1d242b;
  }
  .main-nav__list > li > a:hover {
    color: #ef7d43;
  }
}
.main-nav__list li.menu-item-has-children {
  position: relative;
}
.main-nav__list li.menu-item-has-children > a {
  position: relative;
}
.main-nav__list li.menu-item-has-children > a:after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0.5rem;
  width: 0;
  height: 0;
  z-index: 1;
  border-style: solid;
  border-width: 5px 5px 0 5px;
  border-color: white transparent transparent transparent;
}
@media (min-width: 1280px) {
  .main-nav__list li.menu-item-has-children > a:after {
    display: none;
  }
}
.main-nav__list li.menu-item-has-children a.is-active {
  color: #1d242b;
}
.main-nav__list li.menu-item-has-children a.is-active:after {
  border-color: #1d242b transparent transparent transparent;
}
.main-nav__list li.menu-item-has-children .sub-menu.is-open {
  display: block;
}
.main-nav__list li.menu-item-has-children .sub-menu {
  display: none;
}
.main-nav__list .sub-menu {
  padding: 0 0 0 1.6666666667rem;
  margin: 1.6666666667rem 0 0;
  list-style-type: none;
  border-left: 5px solid rgba(255, 255, 255, 0.1);
}
@media (min-width: 1280px) {
  .main-nav__list .sub-menu {
    position: absolute;
    top: 7rem;
    right: -1rem;
    z-index: 1;
    width: 20rem;
    padding: 2.5rem;
    margin: 0;
    border: none;
    border-radius: 1rem;
    background-color: #ef7d43;
  }
}
@media (min-width: 1280px) {
  .main-nav__list .sub-menu a:hover {
    color: #1d242b;
  }
}

.pg-header--portal .tel-login {
  width: 100%;
  justify-content: space-between;
}

.email-signup__form-fields {
  display: grid;
  grid-gap: 1.6666666667rem;
}
@media (min-width: 768px) {
  .email-signup__form-fields {
    grid-template-columns: repeat(3, 1fr);
  }
}

.email-signup__form-field label {
  display: block;
  width: 100%;
  margin: 0 0 1rem;
  font-size: 1.8rem;
  color: #1d242b;
}
.email-signup__form-field input[type=text],
.email-signup__form-field input[type=email] {
  display: block;
  width: 100%;
  height: 5rem;
  padding: 0 1rem;
  border: none;
  border-radius: 0.5rem;
  font-size: 1.8rem;
  font-family: soleto, Arial, sans-serif;
}
.email-signup__form-field input[type=text]:focus,
.email-signup__form-field input[type=email]:focus {
  outline: none;
  border: 2px solid #1d242b;
}

.email-signup__form-gdpr {
  position: relative;
  padding: 0 0 0 2.5rem;
}
.email-signup__form-gdpr input {
  position: absolute;
  top: 0.25rem;
  left: 0;
  z-index: 1;
}
.email-signup__form-gdpr label {
  font-size: 1.4rem;
  line-height: 1.5;
  color: #1d242b;
}

div.mce_inline_error,
div#mce-error-response,
div#mce-success-response {
  margin: 1rem 0 0;
  padding: 1rem;
  border-radius: 0.5rem;
  font-size: 1.4rem;
  color: white;
}

div.mce_inline_error,
div#mce-error-response {
  background-color: #DB0000;
}

div#mce-success-response {
  background-color: #55a630;
}

.pg-footer-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 0 2.5rem;
  margin: 0 0 2.5rem;
  border-bottom: 1px solid #76868b;
}
@media (min-width: 650px) {
  .pg-footer-header {
    flex-direction: row;
    justify-content: space-between;
  }
}
@media (min-width: 768px) {
  .pg-footer-header {
    padding: 0 0 5rem;
    margin: 0 0 5rem;
  }
}

.pg-footer__logo {
  display: block;
  height: 2.5rem;
  margin: 0 0 2.5rem;
}
@media (min-width: 650px) {
  .pg-footer__logo {
    margin: 0;
  }
}
@media (min-width: 1280px) {
  .pg-footer__logo {
    height: 3rem;
  }
}
.pg-footer__logo svg {
  width: auto;
  height: 100%;
}

.social-list {
  display: flex;
  align-items: center;
  margin: 0 0 2.5rem;
  list-style-type: none;
}
@media (min-width: 650px) {
  .social-list {
    margin: 0;
  }
}

.social-list__link {
  display: block;
  padding: 1rem;
}
.social-list__link svg {
  width: 2rem;
  height: 2rem;
  fill: white;
}

.halma {
  width: 15rem;
}

.halma-logo {
  display: block;
  width: 100%;
  height: auto;
}

.pg-footer-navs {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0 2.5rem;
  margin: 0 0 2.5rem;
  border-bottom: 1px solid #76868b;
}
@media (min-width: 650px) {
  .pg-footer-navs {
    flex-direction: row;
    align-items: flex-start;
  }
}
@media (min-width: 768px) {
  .pg-footer-navs {
    padding: 0 0 5rem;
    margin: 0 0 5rem;
  }
}

.pg-footer-nav {
  text-align: center;
}
@media (min-width: 650px) {
  .pg-footer-nav {
    text-align: left;
  }
}

.pg-footer-nav + .pg-footer-nav {
  margin: 2.5rem 0 0;
}
@media (min-width: 650px) {
  .pg-footer-nav + .pg-footer-nav {
    margin: 0 0 0 2.5rem;
  }
}
@media (min-width: 768px) {
  .pg-footer-nav + .pg-footer-nav {
    margin: 0 0 0 5rem;
  }
}
@media (min-width: 1280px) {
  .pg-footer-nav + .pg-footer-nav {
    margin: 0 0 0 10rem;
  }
}

.pg-footer-nav__list {
  list-style-type: none;
}
.pg-footer-nav__list a {
  color: white;
  text-decoration: none;
}
.pg-footer-nav__list a:hover {
  color: #ef7d43;
}

.pg-footer-terms {
  text-align: center;
}
@media (min-width: 650px) {
  .pg-footer-terms {
    text-align: left;
  }
}

.pg-footer-terms__list {
  list-style-type: none;
}
@media (min-width: 650px) {
  .pg-footer-terms__list {
    display: flex;
  }
}
@media (min-width: 650px) {
  .pg-footer-terms__list li + li {
    margin: 0 0 0 2.5rem;
  }
}
.pg-footer-terms__list a {
  color: white;
  text-decoration: none;
}
.pg-footer-terms__list a:hover {
  color: #ef7d43;
}

.copyright {
  color: white;
}

.title, .woocommerce-order-details__title, .woocommerce-checkout > h3, .cart-collaterals h2, .product .related > h2, .summary > h2, .woocommerce-loop-product__link h2 {
  font-family: interstate, Arial Bold, sans-serif;
  line-height: 1.2;
}
.title a, .woocommerce-order-details__title a, .woocommerce-checkout > h3 a, .cart-collaterals h2 a, .product .related > h2 a, .summary > h2 a, .woocommerce-loop-product__link h2 a {
  color: inherit;
}

.title--xl {
  font-size: 3.75rem;
  letter-spacing: -0.15rem;
}
@media (min-width: 1280px) {
  .title--xl {
    font-size: 5rem;
  }
}

.title--lg, .product .related > h2, .summary > h2 {
  font-size: 2.7rem;
  letter-spacing: -0.1rem;
}
@media (min-width: 1280px) {
  .title--lg, .product .related > h2, .summary > h2 {
    font-size: 3.6rem;
  }
}

.title--md, .woocommerce-order-details__title, .woocommerce-checkout > h3 {
  font-size: 1.8rem;
  letter-spacing: -0.1rem;
}
@media (min-width: 1280px) {
  .title--md, .woocommerce-order-details__title, .woocommerce-checkout > h3 {
    font-size: 2.4rem;
  }
}

.title--sm, .cart-collaterals h2, .woocommerce-loop-product__link h2 {
  font-size: 1.8rem;
  letter-spacing: -0.1rem;
}

.supporting-text {
  font-size: 1.575rem;
}
@media (min-width: 1280px) {
  .supporting-text {
    font-size: 2.1rem;
  }
}
.supporting-text a {
  color: black;
}
.supporting-text a:hover {
  color: #ef7d43;
}

.feed-text {
  font-size: 1.4rem;
}

.text-black {
  color: black;
}

.text-grey-dark, .woocommerce-order-details__title, .woocommerce-checkout > h3, .cart-collaterals h2, .product .related > h2, .summary > h2, .woocommerce-loop-product__link h2 {
  color: #1d242b;
}

.title.text-grey-dark a:hover, .woocommerce-order-details__title a:hover, .woocommerce-checkout > h3 a:hover, .cart-collaterals h2 a:hover, .product .related > h2 a:hover, .summary > h2 a:hover, .woocommerce-loop-product__link h2 a:hover,
.title.text-white a:hover {
  color: #ef7d43;
}

.text-white {
  color: white;
}

.text-orange-dark {
  color: #ef7d43;
}

.title.text-orange-dark a:hover, .text-orange-dark.woocommerce-order-details__title a:hover, .woocommerce-checkout > h3.text-orange-dark a:hover, .cart-collaterals h2.text-orange-dark a:hover, .product .related > h2.text-orange-dark a:hover, .summary > h2.text-orange-dark a:hover, .woocommerce-loop-product__link h2.text-orange-dark a:hover {
  color: black;
}

.btn-xs, .woocommerce-Address-title a, .woocommerce-orders-table__cell-order-actions a, .product-info .posted_in a {
  display: inline-block;
  height: 2rem;
  padding: 0 1rem;
  border-radius: 1rem;
  font-size: 1.4rem;
  text-decoration: none;
  line-height: 2rem;
  transition: 0.1s all ease-out;
}

.btn-sm, .product .add_to_cart_button, .woocommerce-message a {
  display: inline-block;
  height: 3rem;
  padding: 0 1.5rem;
  border-radius: 1.5rem;
  font-size: 1.4rem;
  text-decoration: none;
  line-height: 3rem;
  transition: 0.1s all ease-out;
}

.btn-lg, .woocommerce-checkout-payment button, .cart-collaterals .checkout-button, .add-to-cart-actions button, .tml-button, .wpcf7-submit {
  display: inline-block;
  align-items: center;
  height: 4rem;
  padding: 0 2rem;
  border-radius: 2rem;
  font-size: 1.4rem;
  text-decoration: none;
  line-height: 4rem;
  transition: 0.1s all ease-out;
}
@media (min-width: 768px) {
  .btn-lg, .woocommerce-checkout-payment button, .cart-collaterals .checkout-button, .add-to-cart-actions button, .tml-button, .wpcf7-submit {
    font-size: 1.8rem;
  }
}

input.btn-lg, .cart-collaterals input.checkout-button, input.tml-button, input.wpcf7-submit,
input.btn-sm,
.product input.add_to_cart_button,
input.btn-xs {
  border: none;
}

.btn--orange, .woocommerce-Address-title a, .woocommerce-orders-table__cell-order-actions a, .woocommerce-checkout-payment button, .cart-collaterals .checkout-button, .add-to-cart-actions button, .product .add_to_cart_button, .tml-button, .wpcf7-submit {
  background-color: #ef7d43;
  color: white;
}
.btn--orange:hover, .woocommerce-Address-title a:hover, .woocommerce-orders-table__cell-order-actions a:hover, .woocommerce-checkout-payment button:hover, .cart-collaterals .checkout-button:hover, .add-to-cart-actions button:hover, .product .add_to_cart_button:hover, .tml-button:hover, .wpcf7-submit:hover {
  background-color: #1d242b;
}

.btn--orange-fade {
  background-color: rgba(239, 125, 67, 0.15);
  color: #ef7d43;
}
.btn--orange-fade:hover {
  background-color: #ef7d43;
  color: white;
}

.btn--grey-dark, .product-info .posted_in a, .woocommerce-message a {
  background-color: #1d242b;
  color: white;
}
.btn--grey-dark:hover, .product-info .posted_in a:hover, .woocommerce-message a:hover {
  background-color: black;
}

.btn--white {
  background-color: white;
  color: #1d242b;
}
.btn--white:hover {
  background-color: #1d242b;
  color: white;
}

.btn-group {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  list-style-type: none;
}
.no-flexbox-gap .btn-group {
  margin: -0.25rem;
}
.no-flexbox-gap .btn-group > * {
  padding: 0.25rem;
}

.std-content, .woocommerce-customer-details,
.woocommerce-Message {
  width: 100%;
}
.std-content > * + *, .woocommerce-customer-details > * + *,
.woocommerce-Message > * + * {
  margin: 2.5rem 0 0;
}
.std-content h2, .woocommerce-customer-details h2,
.woocommerce-Message h2, .std-content h3, .woocommerce-customer-details h3,
.woocommerce-Message h3, .std-content h4, .woocommerce-customer-details h4,
.woocommerce-Message h4, .std-content h5, .woocommerce-customer-details h5,
.woocommerce-Message h5, .std-content h6, .woocommerce-customer-details h6,
.woocommerce-Message h6 {
  font-family: interstate, Arial Bold, sans-serif;
  line-height: 1.2;
  color: #1d242b;
}
.std-content h2, .woocommerce-customer-details h2,
.woocommerce-Message h2 {
  letter-spacing: -0.15rem;
}
.std-content h3, .woocommerce-customer-details h3,
.woocommerce-Message h3 {
  letter-spacing: -0.15rem;
}
.std-content h4, .woocommerce-customer-details h4,
.woocommerce-Message h4 {
  letter-spacing: -0.1rem;
}
.std-content h5, .woocommerce-customer-details h5,
.woocommerce-Message h5 {
  letter-spacing: -0.1rem;
}
.std-content p a, .woocommerce-customer-details p a,
.woocommerce-Message p a, .std-content ul a, .woocommerce-customer-details ul a,
.woocommerce-Message ul a, .std-content ol a, .woocommerce-customer-details ol a,
.woocommerce-Message ol a {
  color: black;
  text-decoration: underline;
}
.std-content p a:hover, .woocommerce-customer-details p a:hover,
.woocommerce-Message p a:hover, .std-content ul a:hover, .woocommerce-customer-details ul a:hover,
.woocommerce-Message ul a:hover, .std-content ol a:hover, .woocommerce-customer-details ol a:hover,
.woocommerce-Message ol a:hover {
  color: #ef7d43;
}
.std-content p > strong, .woocommerce-customer-details p > strong,
.woocommerce-Message p > strong, .std-content ul > strong, .woocommerce-customer-details ul > strong,
.woocommerce-Message ul > strong, .std-content ol > strong, .woocommerce-customer-details ol > strong,
.woocommerce-Message ol > strong {
  color: black;
}
.std-content p > em, .woocommerce-customer-details p > em,
.woocommerce-Message p > em, .std-content ul > em, .woocommerce-customer-details ul > em,
.woocommerce-Message ul > em, .std-content ol > em, .woocommerce-customer-details ol > em,
.woocommerce-Message ol > em {
  font-style: italic;
}
.std-content p, .woocommerce-customer-details p,
.woocommerce-Message p {
  line-height: 1.5;
}
.std-content sup, .woocommerce-customer-details sup,
.woocommerce-Message sup {
  font-size: 75%;
}
.std-content ul, .woocommerce-customer-details ul,
.woocommerce-Message ul {
  list-style-type: disc;
  margin-left: 1.6666666667rem;
}
.std-content ol, .woocommerce-customer-details ol,
.woocommerce-Message ol {
  list-style-type: decimal;
  margin-left: 1.6666666667rem;
  counter-reset: item;
}
.std-content ul ul, .woocommerce-customer-details ul ul,
.woocommerce-Message ul ul {
  margin: 1rem 0 0 3rem;
  list-style-type: circle;
}
.std-content li, .woocommerce-customer-details li,
.woocommerce-Message li {
  line-height: 1.5;
}
.std-content li + li, .woocommerce-customer-details li + li,
.woocommerce-Message li + li {
  margin-top: 1rem;
}
.std-content img, .woocommerce-customer-details img,
.woocommerce-Message img {
  display: block;
  width: 100%;
  height: auto;
  margin: 5rem auto;
}
.std-content a, .woocommerce-customer-details a,
.woocommerce-Message a {
  color: inherit;
  text-decoration: underline;
}
.std-content address, .woocommerce-customer-details address,
.woocommerce-Message address {
  font-style: normal;
}

.std-content img.alignleft, .woocommerce-customer-details img.alignleft,
.woocommerce-Message img.alignleft,
.std-content img.alignright,
.woocommerce-customer-details img.alignright,
.woocommerce-Message img.alignright {
  width: 50%;
  margin: 5rem auto;
}
@media (min-width: 450px) {
  .std-content img.alignleft, .woocommerce-customer-details img.alignleft,
.woocommerce-Message img.alignleft,
.std-content img.alignright,
.woocommerce-customer-details img.alignright,
.woocommerce-Message img.alignright {
    width: 20%;
    clear: both;
    margin-top: 0.5rem;
    margin-bottom: 2.5rem;
  }
}
@media (min-width: 1280px) {
  .std-content img.alignleft, .woocommerce-customer-details img.alignleft,
.woocommerce-Message img.alignleft,
.std-content img.alignright,
.woocommerce-customer-details img.alignright,
.woocommerce-Message img.alignright {
    width: 25%;
  }
}
@media (min-width: 450px) {
  .std-content img.alignleft, .woocommerce-customer-details img.alignleft,
.woocommerce-Message img.alignleft {
    float: left;
    margin-right: 5rem;
  }
}
@media (min-width: 450px) {
  .std-content img.alignright, .woocommerce-customer-details img.alignright,
.woocommerce-Message img.alignright {
    float: right;
    margin-left: 5rem;
  }
}
.std-content img.alignnone, .woocommerce-customer-details img.alignnone,
.woocommerce-Message img.alignnone {
  clear: both;
}
.std-content .wp-caption, .woocommerce-customer-details .wp-caption,
.woocommerce-Message .wp-caption {
  width: 100% !important;
  height: auto;
  margin: 5rem auto;
}
.std-content .wp-caption img, .woocommerce-customer-details .wp-caption img,
.woocommerce-Message .wp-caption img {
  margin: 0;
}
.std-content p.wp-caption-text, .woocommerce-customer-details p.wp-caption-text,
.woocommerce-Message p.wp-caption-text {
  margin: 0 auto;
  padding: 0.5rem;
  font-size: 2.1rem;
  line-height: 1.5;
  text-align: center;
}
.std-content .video-embed, .woocommerce-customer-details .video-embed,
.woocommerce-Message .video-embed {
  clear: both;
  margin: 5rem 0;
}

.hero {
  position: relative;
  padding: 5rem 0;
}
@media (min-width: 768px) {
  .hero {
    padding: 10rem 0;
  }
}
.hero .inner {
  position: relative;
  z-index: 3;
}

.hero--home:before {
  content: "";
  position: absolute;
  top: -35vw;
  right: -15vw;
  z-index: 1;
  width: 60vw;
  padding-bottom: 60vw;
  background: linear-gradient(90deg, #fab500 0%, #ef7d43 100%);
  border-radius: 100%;
}
.hero--home:after {
  content: "";
  position: absolute;
  top: -25vw;
  right: -5vw;
  z-index: 2;
  width: 40vw;
  padding-bottom: 40vw;
  background: white;
  border-radius: 100%;
}

.hero-content {
  position: relative;
}
@media (min-width: 450px) {
  .hero-content {
    display: flex;
    flex-wrap: wrap;
  }
}
@media (min-width: 768px) {
  .hero-content {
    padding: 0 40% 0 0;
  }
}
@media (min-width: 1024px) {
  .hero-content {
    padding: 0 50% 0 0;
  }
}

@media (min-width: 450px) {
  .hero-content__text {
    width: 50%;
  }
}
@media (min-width: 768px) {
  .hero-content__text {
    width: 100%;
  }
}
.hero-content__text h1 {
  position: relative;
}

.hero-content__img-outer {
  width: 60%;
  margin: 2.5rem auto;
}
@media (min-width: 450px) {
  .hero-content__img-outer {
    width: 50%;
    margin: 0;
  }
}
@media (min-width: 768px) {
  .hero-content__img-outer {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    width: 40%;
  }
}
@media (min-width: 1024px) {
  .hero-content__img-outer {
    width: 50%;
  }
}

.hero-content__img {
  display: block;
  width: 100%;
  height: auto;
}
@media (min-width: 450px) {
  .hero-content__img {
    width: 50%;
    margin: 0 auto;
  }
}

.hero-cta {
  display: grid;
  grid-gap: 2.5rem;
  width: 100%;
  padding: 2.5rem;
  margin: 2.5rem 0 0;
  background-color: white;
  border-radius: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
@media (min-width: 1280px) {
  .hero-cta {
    margin: 5rem 0 0;
  }
}

.hero-cta {
  display: grid;
  grid-gap: 2.5rem;
  padding: 2.5rem;
  margin: 2.5rem 0 0;
  background-color: white;
  border-radius: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
@media (min-width: 1280px) {
  .hero-cta {
    margin: 5rem 0 0;
  }
}

.site-search {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 4rem;
  border-radius: 3px;
  background-color: #f5f5f5;
}
@media (min-width: 1280px) {
  .site-search {
    height: 5rem;
  }
}

.site-search__text-field {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  padding: 0 5rem 0 1rem;
  border: none;
  background: none;
  font-size: 1.8rem;
}

.site-search__btn {
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
  z-index: 2;
  display: grid;
  place-content: center;
  width: 3rem;
  height: 3rem;
  border-radius: 100%;
  background-color: #1d242b;
  transition: 0.1s all ease-out;
}
.site-search__btn:hover {
  background-color: #ef7d43;
}
.site-search__btn svg {
  width: 1.5rem;
  height: 1.5rem;
  fill: white;
}

.main-nav-outer .site-search {
  display: none;
  position: absolute;
  top: 9rem;
  left: 2.5rem;
  z-index: 2;
  width: calc(100% - 5rem);
  background-color: white;
}
@media (min-width: 768px) {
  .main-nav-outer .site-search {
    top: 1.6666666667rem;
    left: 17.5rem;
    display: flex;
    width: 30rem;
    background-color: #f5f5f5;
  }
}
@media (min-width: 1280px) {
  .main-nav-outer .site-search {
    top: 1.5rem;
    left: 20rem;
  }
}
.main-nav-outer .site-search.is-visible {
  display: flex;
}

.search-results {
  list-style-type: none;
}

.search-result + .search-result {
  margin: 2.5rem 0 0;
  padding: 2.5rem 0 0;
  border-top: 1px solid #f5f5f5;
}

.product-category-feed {
  display: grid;
  grid-gap: 2.5rem;
  padding: 2.5rem;
  margin: 0 2.5rem;
  border-radius: 2rem;
  background-color: #f5f5f5;
}
@media (min-width: 768px) {
  .product-category-feed {
    justify-items: center;
    padding: 5rem;
  }
}
@media (min-width: 1024px) {
  .product-category-feed {
    margin: 0 2.5rem;
  }
}
@media (min-width: 1280px) {
  .product-category-feed {
    grid-gap: 5rem;
    padding: 5rem 10rem;
  }
}

.product-category-feed__list {
  display: flex;
  flex-direction: column;
  gap: 1.6666666667rem;
  list-style-type: none;
}
.no-flexbox-gap .product-category-feed__list {
  margin: -1rem;
}
.no-flexbox-gap .product-category-feed__list > * {
  padding: 1rem;
}
@media (min-width: 650px) {
  .product-category-feed__list {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
}
@media (min-width: 1280px) {
  .product-category-feed__list {
    gap: 5rem;
  }
}
@media (min-width: 650px) {
  .product-category-feed__list li {
    width: calc(50% - 1.6666666667rem);
  }
}
@media (min-width: 768px) {
  .product-category-feed__list li {
    width: 12rem;
  }
}

.product-category-feed__link {
  display: flex;
  align-items: center;
  font-size: 1.8rem;
  text-decoration: none;
  color: #1d242b;
  line-height: 1.2;
}
@media (min-width: 768px) {
  .product-category-feed__link {
    flex-direction: column;
    text-align: center;
  }
}
.product-category-feed__link:hover .product-category-feed__img-outer {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.product-category-feed__img-outer {
  display: grid;
  place-items: center;
  width: 5rem;
  height: 5rem;
  margin: 0 1.6666666667rem 0 0;
  background-color: white;
  border-radius: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: 0.1s all ease-out;
}
@media (min-width: 768px) {
  .product-category-feed__img-outer {
    width: 12rem;
    height: 12rem;
    margin: 0 0 1.6666666667rem;
  }
}

.product-category-feed__img {
  display: block;
  width: 60%;
  height: 60%;
  object-fit: contain;
}

.usps-media-outer {
  position: relative;
}
.usps-media-outer .inner {
  position: relative;
  z-index: 2;
}
.usps-media-outer:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 50%;
  background-color: #1d242b;
}

.usps-media {
  width: 100%;
  border-radius: 0.5rem;
  overflow: hidden;
  aspect-ratio: 16/9;
}
@supports not (aspect-ratio: 16/9) {
  .usps-media:before {
    float: left;
    padding-top: 56.25%;
    content: "";
  }
  .usps-media:after {
    display: block;
    content: "";
    clear: both;
  }
}
.usps-media iframe {
  width: 100%;
  height: 100%;
}

.usps-media__img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.usps-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 2.5rem;
  justify-content: center;
  list-style-type: none;
}
.no-flexbox-gap .usps-grid {
  margin: -1rem;
}
.no-flexbox-gap .usps-grid > * {
  padding: 1rem;
}
@media (min-width: 1280px) {
  .usps-grid {
    gap: 5rem;
  }
}

.usps-grid__item {
  max-width: 25%;
  text-align: center;
  color: white;
}
.usps-grid__item p {
  font-size: inherit;
}

.contact-cta {
  display: grid;
  justify-items: center;
  grid-gap: 2.5rem;
}
@media (min-width: 1280px) {
  .contact-cta {
    grid-grap: 5rem;
  }
}
.contact-cta .title, .contact-cta .woocommerce-order-details__title, .contact-cta .woocommerce-checkout > h3, .contact-cta .cart-collaterals h2, .cart-collaterals .contact-cta h2, .contact-cta .product .related > h2, .product .contact-cta .related > h2, .contact-cta .summary > h2, .contact-cta .woocommerce-loop-product__link h2, .woocommerce-loop-product__link .contact-cta h2 {
  color: #1d242b;
}

.contact-cta__links {
  display: grid;
  grid-gap: 1.6666666667rem;
  width: 100%;
  list-style-type: none;
}
@media (min-width: 650px) {
  .contact-cta__links {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 768px) {
  .contact-cta__links {
    max-width: 80%;
    grid-gap: 2.5rem;
  }
}

.contact-cta__link {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 1.6666666667rem;
  background-color: #f5f5f5;
  border-radius: 1rem;
  font-family: interstate, Arial Bold, sans-serif;
  line-height: 1.2;
  font-size: 1.8rem;
  letter-spacing: -0.1rem;
  color: #1d242b;
  text-decoration: none;
  transition: 0.1s all ease-out;
}
.contact-cta__link:hover {
  background-color: #1d242b;
  color: white;
}
@media (min-width: 768px) {
  .contact-cta__link {
    flex-direction: row;
    justify-content: center;
    padding: 2.5rem;
  }
}
@media (min-width: 1280px) {
  .contact-cta__link {
    font-size: 2.4rem;
  }
}

.contact-cta__link__icon {
  display: grid;
  place-items: center;
  width: 3rem;
  height: 3rem;
  margin: 0 0 0.5rem;
  background-color: #ef7d43;
  border-radius: 100%;
}
@media (min-width: 768px) {
  .contact-cta__link__icon {
    margin: 0 1.6666666667rem 0 0;
  }
}
.contact-cta__link__icon svg {
  width: 1.5rem;
  height: 1.5rem;
  fill: white;
}

.contact-cta__location-alert {
  color: #76868b;
  text-align: center;
  line-height: 1.2;
}
.contact-cta__location-alert:hover {
  color: #ef7d43;
}

.bg-grey-dark .contact-cta {
  padding: 2.5rem;
  background-color: black;
  border-radius: 1rem 1rem 0 0;
}
@media (min-width: 768px) {
  .bg-grey-dark .contact-cta {
    padding: 2.5rem 0;
  }
}
@media (min-width: 1280px) {
  .bg-grey-dark .contact-cta {
    padding: 5rem 0;
  }
}
.bg-grey-dark .contact-cta .title, .bg-grey-dark .contact-cta .woocommerce-order-details__title, .bg-grey-dark .contact-cta .woocommerce-checkout > h3, .bg-grey-dark .contact-cta .cart-collaterals h2, .cart-collaterals .bg-grey-dark .contact-cta h2, .bg-grey-dark .contact-cta .product .related > h2, .product .bg-grey-dark .contact-cta .related > h2, .bg-grey-dark .contact-cta .summary > h2, .bg-grey-dark .contact-cta .woocommerce-loop-product__link h2, .woocommerce-loop-product__link .bg-grey-dark .contact-cta h2 {
  color: white;
}
.bg-grey-dark .contact-cta .contact-cta__link {
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
}
.bg-grey-dark .contact-cta .contact-cta__link:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.bg-grey-light .contact-cta__link, .main-content--portal .product .pg-content__section:nth-child(odd) > div .contact-cta__link {
  background-color: white;
}
.bg-grey-light .contact-cta__link:hover, .main-content--portal .product .pg-content__section:nth-child(odd) > div .contact-cta__link:hover {
  background-color: #1d242b;
}

.hero-contact-cta__links {
  display: grid;
  grid-gap: 1.6666666667rem;
  width: 100%;
  list-style-type: none;
}
@media (min-width: 1024px) {
  .hero-contact-cta__links {
    grid-template-columns: 1fr 1fr;
  }
}

.hero-contact-cta__link {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 1.6666666667rem;
  background-color: #f5f5f5;
  border-radius: 1rem;
  font-family: interstate, Arial Bold, sans-serif;
  line-height: 1.2;
  font-size: 1.8rem;
  letter-spacing: -0.1rem;
  color: #1d242b;
  text-decoration: none;
  transition: 0.1s all ease-out;
}
@media (min-width: 1280px) {
  .hero-contact-cta__link {
    flex-direction: row;
    justify-content: center;
  }
}
.hero-contact-cta__link:hover {
  background-color: #1d242b;
  color: white;
}

.hero-contact-cta__link__icon {
  display: grid;
  place-items: center;
  width: 2rem;
  height: 2rem;
  background-color: #ef7d43;
  border-radius: 100%;
}
@media (min-width: 1280px) {
  .hero-contact-cta__link__icon {
    margin: 0 1rem 0 0;
  }
}
.hero-contact-cta__link__icon svg {
  width: 1rem;
  height: 1rem;
  fill: white;
}

.text-img {
  display: grid;
  grid-gap: 2.5rem;
}
@media (min-width: 650px) {
  .text-img {
    align-items: center;
  }
}
@media (min-width: 1024px) {
  .text-img {
    grid-template-columns: 1fr 1fr;
    grid-gap: 10rem;
  }
}
@media (min-width: 650px) {
  .text-img.is-aligned {
    align-items: start;
  }
}

@media (min-width: 650px) {
  .text-img--default {
    grid-template-columns: 1fr 2fr;
  }
}

@media (min-width: 650px) {
  .text-img--alt {
    grid-template-columns: 2fr 1fr;
  }
}
@media (min-width: 768px) {
  .text-img--alt .text-img__text {
    order: 1;
  }
}
@media (min-width: 650px) {
  .text-img--alt .text-img__img-outer {
    order: 2;
  }
}

.text-img__img {
  border-radius: 1rem;
  display: block;
  width: 100%;
  height: auto;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .text-img__img {
    flex-shrink: 0;
  }
}

@media (min-width: 1024px) {
  .text-img__text {
    padding: 0.5rem 0 0;
  }
}

.text-img__caption {
  margin: 1.6666666667rem 0 0;
  font-size: 1.8rem;
  color: #ef7d43;
}

.pg-end-ctas {
  display: grid;
  grid-gap: 2.5rem;
  margin: 0 2.5rem;
}
@media (min-width: 768px) {
  .pg-end-ctas {
    grid-template-columns: 1fr 1fr;
  }
}

.pg-end-cta {
  position: relative;
  border-radius: 2rem;
  overflow: hidden;
}

.pg-end-cta__img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.pg-end-cta__content {
  position: relative;
  z-index: 2;
  display: block;
  height: 100%;
  padding: 5rem 2.5rem;
  text-decoration: none;
  transition: 0.1s all ease-out;
}
@media (min-width: 450px) {
  .pg-end-cta__content {
    padding: 5rem 10rem 5rem 2.5rem;
  }
}
@media (min-width: 1280px) {
  .pg-end-cta__content {
    padding: 5rem 20rem 5rem 5rem;
  }
}
@media (min-width: 1600px) {
  .pg-end-cta__content {
    padding: 10rem 20rem 10rem 5rem;
  }
}

.pg-end-cta__link {
  display: grid;
  place-items: center;
  width: 5rem;
  height: 5rem;
  margin: 2.5rem 0 0;
  border-radius: 100%;
}
@media (min-width: 450px) {
  .pg-end-cta__link {
    position: absolute;
    top: 50%;
    right: 2.5rem;
    transform: translateY(-50%);
    z-index: 1;
    margin: 0;
  }
}
@media (min-width: 1280px) {
  .pg-end-cta__link {
    right: 5rem;
  }
}
.pg-end-cta__link svg {
  width: 50%;
  height: auto;
}

.pg-end-cta:first-child .pg-end-cta__content {
  background-color: rgba(239, 125, 67, 0.8);
}
.pg-end-cta:first-child .pg-end-cta__content:hover {
  background-color: rgba(239, 125, 67, 0.9);
}
.pg-end-cta:first-child .title, .pg-end-cta:first-child .woocommerce-order-details__title, .pg-end-cta:first-child .woocommerce-checkout > h3, .pg-end-cta:first-child .cart-collaterals h2, .cart-collaterals .pg-end-cta:first-child h2, .pg-end-cta:first-child .product .related > h2, .product .pg-end-cta:first-child .related > h2, .pg-end-cta:first-child .summary > h2, .pg-end-cta:first-child .woocommerce-loop-product__link h2, .woocommerce-loop-product__link .pg-end-cta:first-child h2 {
  color: white;
}
.pg-end-cta:first-child .supporting-text {
  color: #1d242b;
}
.pg-end-cta:first-child .pg-end-cta__link {
  background-color: #1d242b;
}
.pg-end-cta:first-child .pg-end-cta__link svg {
  fill: white;
}

.pg-end-cta:last-child .pg-end-cta__content {
  background-color: rgba(29, 36, 43, 0.8);
}
.pg-end-cta:last-child .pg-end-cta__content:hover {
  background-color: rgba(29, 36, 43, 0.9);
}
.pg-end-cta:last-child .title, .pg-end-cta:last-child .woocommerce-order-details__title, .pg-end-cta:last-child .woocommerce-checkout > h3, .pg-end-cta:last-child .cart-collaterals h2, .cart-collaterals .pg-end-cta:last-child h2, .pg-end-cta:last-child .product .related > h2, .product .pg-end-cta:last-child .related > h2, .pg-end-cta:last-child .summary > h2, .pg-end-cta:last-child .woocommerce-loop-product__link h2, .woocommerce-loop-product__link .pg-end-cta:last-child h2 {
  color: #ef7d43;
}
.pg-end-cta:last-child .supporting-text {
  color: white;
}
.pg-end-cta:last-child .pg-end-cta__link {
  background-color: white;
}
.pg-end-cta:last-child .pg-end-cta__link svg {
  fill: #1d242b;
}

.product-feed-intro {
  max-width: 768px;
  margin: 0 auto 5rem;
  text-align: center;
}

.product-feed {
  display: grid;
  grid-gap: 1.6666666667rem;
  list-style-type: none;
}

@media (min-width: 650px) {
  .product-feed--2-col {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 650px) {
  .product-feed--4-col {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 768px) {
  .product-feed--4-col {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (min-width: 1280px) {
  .product-feed--4-col {
    grid-template-columns: repeat(4, 1fr);
  }
}

.system-card,
.vehicle-fitting-card {
  border-radius: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: white;
  overflow: hidden;
}
@media (min-width: 1024px) {
  .system-card,
.vehicle-fitting-card {
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    align-items: center;
    justify-items: center;
  }
}

.system-card__img-outer {
  display: block;
  height: 20rem;
  padding: 2.5rem;
  border-bottom: 1px solid #f5f5f5;
}
@media (min-width: 1024px) {
  .system-card__img-outer {
    height: 25rem;
    border: 0;
  }
}

.vehicle-fitting-card__img-outer {
  display: block;
  height: 20rem;
  padding: 2.5rem;
}
@media (min-width: 1024px) {
  .vehicle-fitting-card__img-outer {
    height: 25rem;
  }
}

.system-card__img {
  display: block;
  width: auto;
  height: 100%;
  margin: 0 auto;
}

.vehicle-fitting-card__img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 0.5rem;
  overflow: hidden;
}

.system-card__info,
.vehicle-fitting-card__info {
  display: grid;
  gap: 2.5rem;
  justify-items: start;
  padding: 2.5rem;
}
@media (min-width: 1024px) {
  .system-card__info,
.vehicle-fitting-card__info {
    padding: 5rem 2.5rem;
  }
}

.system-card__logo {
  display: block;
  width: 12rem;
  height: 3rem;
  object-fit: contain;
  object-position: left center;
}

.product-card {
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: white;
}

.product-card__img-outer {
  display: block;
  height: 20rem;
  padding: 2.5rem;
}
@media (min-width: 1024px) {
  .product-card__img-outer {
    height: 25rem;
    padding: 5rem;
  }
}

.product-card__img {
  display: block;
  width: auto;
  height: 100%;
  margin: 0 auto;
}

.product-card__info {
  flex: 1;
  position: relative;
  padding: 2.5rem 2.5rem 10rem;
  background-color: #f5f5f5;
}
.product-card__info > .btn-sm, .product .product-card__info > .add_to_cart_button, .woocommerce-message .product-card__info > a {
  position: absolute;
  bottom: 2.5rem;
  left: 2.5rem;
  z-index: 1;
}

.product-id, .product-info .product_meta .sku_wrapper, .summary .price,
.summary #product_total_price {
  font-family: interstate, Arial Bold, sans-serif;
  font-size: 1.4rem;
}

.product-card__related__title {
  margin: 0 0 0.5rem;
  font-family: interstate, Arial Bold, sans-serif;
  font-size: 1.4rem;
  color: #1d242b;
  letter-spacing: -0.1rem;
}

.vehicle-logos {
  display: flex;
  gap: 5rem;
  flex-wrap: wrap;
  justify-content: center;
  list-style-type: none;
}
.no-flexbox-gap .vehicle-logos {
  margin: -2.5rem;
}
.no-flexbox-gap .vehicle-logos > * {
  padding: 2.5rem;
}
@media (min-width: 1280px) {
  .vehicle-logos {
    gap: 10rem;
  }
}

.gallery-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.gallery-slider-outer {
  position: relative;
}
@media (min-width: 768px) {
  .gallery-slider-outer > .tns-outer {
    width: calc(100% - 10rem);
    margin: 0 auto;
  }
}
@media screen and (min-width: 1350px) {
  .gallery-slider-outer > .tns-outer {
    width: 100%;
  }
}

.gallery-control {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  display: grid;
  place-items: center;
  width: 3rem;
  height: 3rem;
  border-radius: 100%;
  background-color: #ef7d43;
}
.gallery-control svg {
  width: 1.5rem;
  height: 1.5rem;
  fill: white;
}
.gallery-control:hover {
  background-color: #1d242b;
}

.gallery-control--prev {
  left: 1rem;
}
@media screen and (min-width: 1350px) {
  .gallery-control--prev {
    left: -5rem;
  }
}
.gallery-control--prev svg {
  transform: rotate(-180deg);
}

.gallery-control--next {
  right: 1rem;
}
@media screen and (min-width: 1350px) {
  .gallery-control--next {
    right: -5rem;
  }
}

.gallery {
  position: relative;
  z-index: 1;
}

.gallery__img-outer {
  aspect-ratio: 16/9;
  padding: 0 1rem;
}
@supports not (aspect-ratio: 16/9) {
  .gallery__img-outer:before {
    float: left;
    padding-top: 56.25%;
    content: "";
  }
  .gallery__img-outer:after {
    display: block;
    content: "";
    clear: both;
  }
}

.gallery__img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 1rem;
  overflow: hidden;
}

.cat-list {
  display: flex;
  gap: 1rem;
  list-style-type: none;
}
.no-flexbox-gap .cat-list {
  margin: -0.5rem;
}
.no-flexbox-gap .cat-list > * {
  padding: 0.5rem;
}

.cat-list__cat {
  font-size: 1.4rem;
  color: #1d242b;
}

.cat-list__cat:before {
  content: "";
  display: inline-block;
  width: 8px;
  height: 8px;
  margin: 0 0.5rem 0 0;
  border-radius: 100%;
  background-color: #ef7d43;
}

.post-header {
  padding: 5rem 0;
  margin: 0 0 5rem;
  border-bottom: 1px solid #76868b;
}

.post-meta {
  display: flex;
  flex-wrap: wrap;
  gap: 1.6666666667rem;
}

.post-meta__author {
  font-size: 1.4rem;
}

.post-meta__date {
  font-size: 1.4rem;
  color: #1d242b;
}

@media (min-width: 768px) {
  .blog-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.blog-header .title, .blog-header .woocommerce-order-details__title, .blog-header .woocommerce-checkout > h3, .blog-header .cart-collaterals h2, .cart-collaterals .blog-header h2, .blog-header .product .related > h2, .product .blog-header .related > h2, .blog-header .summary > h2, .blog-header .woocommerce-loop-product__link h2, .woocommerce-loop-product__link .blog-header h2 {
  margin: 0 0 1.6666666667rem;
}
@media (min-width: 768px) {
  .blog-header .title, .blog-header .woocommerce-order-details__title, .blog-header .woocommerce-checkout > h3, .blog-header .cart-collaterals h2, .cart-collaterals .blog-header h2, .blog-header .product .related > h2, .product .blog-header .related > h2, .blog-header .summary > h2, .blog-header .woocommerce-loop-product__link h2, .woocommerce-loop-product__link .blog-header h2 {
    margin: 0;
  }
}

.latest-post-feed {
  display: grid;
  grid-gap: 2.5rem;
  list-style-type: none;
}
@media (min-width: 768px) {
  .latest-post-feed {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 768px) {
  .latest-post-feed__item {
    position: relative;
    border-radius: 1rem;
    background-color: rgba(255, 255, 255, 0.1);
    overflow: hidden;
  }
}
.latest-post-feed__item .cat-list__cat,
.latest-post-feed__item .post-meta__date {
  color: white;
}
@media (min-width: 768px) {
  .latest-post-feed__item .cat-list__cat {
    position: absolute;
    bottom: 5rem;
    left: 2.5rem;
  }
}
@media (min-width: 768px) {
  .latest-post-feed__item .post-feed__content {
    padding: 2.5rem 1.6666666667rem 12rem;
  }
}
@media (min-width: 1280px) {
  .latest-post-feed__item .post-feed__content {
    padding: 2.5rem 2.5rem 12rem;
  }
}

.latest-post-feed__item + .latest-post-feed__item {
  padding: 2.5rem 0 0;
  border-top: 1px solid #76868b;
}
@media (min-width: 768px) {
  .latest-post-feed__item + .latest-post-feed__item {
    padding: 0;
    border: none;
  }
}

.latest-post-feed__img-outer {
  display: none;
}
@media (min-width: 768px) {
  .latest-post-feed__img-outer {
    display: block;
    aspect-ratio: 16/9;
  }
  @supports not (aspect-ratio: 16/9) {
    .latest-post-feed__img-outer:before {
      float: left;
      padding-top: 56.25%;
      content: "";
    }
    .latest-post-feed__img-outer:after {
      display: block;
      content: "";
      clear: both;
    }
  }
}

.latest-post-feed__img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.post-feed {
  display: grid;
  grid-gap: 2.5rem;
  list-style-type: none;
}
@media (min-width: 768px) {
  .post-feed {
    grid-template-columns: 1fr 1fr;
  }
}
@media (min-width: 1280px) {
  .post-feed {
    grid-gap: 5rem;
  }
}

@media (min-width: 768px) {
  .post-feed__item {
    display: flex;
  }
}

.post-feed__item + .post-feed__item {
  padding: 2.5rem 0 0;
  border-top: 1px solid #f5f5f5;
}
@media (min-width: 768px) {
  .post-feed__item + .post-feed__item {
    padding: 0;
    border: none;
  }
}

.post-feed__img {
  display: none;
}
@media (min-width: 768px) {
  .post-feed__img {
    display: block;
    width: 10rem;
    height: 10rem;
    margin: 0 2.5rem 0 0;
    object-fit: cover;
    object-position: center;
    border-radius: 0.5rem;
    overflow: hidden;
  }
}

@media (min-width: 768px) {
  .post-feed .post-feed__content {
    position: relative;
    flex: 1;
    padding: 0.5rem 0 6rem;
  }
}
@media (min-width: 768px) {
  .post-feed .post-feed__content .cat-list__cat {
    position: absolute;
    bottom: 0;
    left: 0;
  }
}

@media (min-width: 768px) {
  .post-feed--archive .post-feed__content {
    padding: 0.5rem 0 0;
  }
}

.product-hero-outer {
  position: relative;
  padding: 5rem 0 0;
  background-color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}
@media (min-width: 768px) {
  .product-hero-outer {
    padding: 10rem 0 0;
  }
}
.product-hero-outer .inner {
  position: relative;
  z-index: 2;
}
.product-hero-outer:before {
  content: "";
  position: absolute;
  top: -10vw;
  right: -40vw;
  z-index: 1;
  width: 60vw;
  padding-bottom: 60vw;
  background: linear-gradient(90deg, #fab500 0%, #ef7d43 100%);
  border-radius: 100%;
}

.product-hero {
  display: grid;
  grid-gap: 5rem;
}
@media (min-width: 768px) {
  .product-hero {
    grid-template-columns: 1fr 1fr;
  }
}
@media (min-width: 1280px) {
  .product-hero {
    grid-gap: 10rem;
  }
}

@media (min-width: 768px) {
  .product-info {
    order: 2;
  }
}

.product-info__creds {
  display: flex;
  flex-wrap: wrap;
  gap: 1.6666666667rem;
  list-style-type: none;
}
.no-flexbox-gap .product-info__creds {
  margin: -0.5rem;
}
.no-flexbox-gap .product-info__creds > * {
  padding: 0.5rem;
}

.product-info__cred {
  display: block;
  width: auto;
  height: 4rem;
  object-fit: contain;
}

.product-gallery-outer {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
@media (min-width: 768px) {
  .product-gallery-outer {
    order: 1;
  }
}

.product-gallery-control {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  display: grid;
  place-items: center;
  width: 3rem;
  height: 3rem;
  border-radius: 100%;
  background-color: #ef7d43;
}
.product-gallery-control svg {
  width: 1.5rem;
  height: 1.5rem;
  fill: white;
}
.product-gallery-control:hover {
  background-color: #1d242b;
}

.product-gallery-control--prev {
  left: 2.5rem;
}
.product-gallery-control--prev svg {
  transform: rotate(-180deg);
}

.product-gallery-control--next {
  right: 2.5rem;
}

.product-gallery__img-outer {
  width: 100%;
  aspect-ratio: 3/2;
}
@supports not (aspect-ratio: 3/2) {
  .product-gallery__img-outer:before {
    float: left;
    padding-top: 66.6666666667%;
    content: "";
  }
  .product-gallery__img-outer:after {
    display: block;
    content: "";
    clear: both;
  }
}

.product-gallery__img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.product-nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2.5rem 0;
}
@media (min-width: 1280px) {
  .product-nav {
    padding: 5rem 0;
  }
}
.product-nav .btn-group {
  justify-content: center;
  flex-wrap: wrap;
}

.gallery-counter {
  flex-shrink: 0;
  padding: 0.5rem;
  font-size: 1.4rem;
  border-radius: 0.5rem;
}

.bg-grey-light .gallery-counter, .main-content--portal .product .pg-content__section:nth-child(odd) > div .gallery-counter {
  background-color: white;
}

.bg-white .gallery-counter {
  background-color: #f5f5f5;
}

.product-gallery-outer .gallery-counter {
  background-color: #f5f5f5;
}

.video-gallery {
  display: grid;
  grid-gap: 2.5rem;
}
@media (min-width: 1024px) {
  .video-gallery {
    align-items: center;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10rem;
  }
}

.video-gallery__video {
  aspect-ratio: 16/9;
}
@supports not (aspect-ratio: 16/9) {
  .video-gallery__video:before {
    float: left;
    padding-top: 56.25%;
    content: "";
  }
  .video-gallery__video:after {
    display: block;
    content: "";
    clear: both;
  }
}
.video-gallery__video iframe {
  display: block;
  width: 100%;
  height: 100%;
}

@media (min-width: 768px) {
  .key-features {
    display: flex;
    align-items: flex-start;
    gap: 5rem;
  }
  .no-flexbox-gap .key-features {
    margin: -1rem;
  }
  .no-flexbox-gap .key-features > * {
    padding: 1rem;
  }
}
.key-features__text-outer {
  display: grid;
  grid-gap: 2.5rem;
}
@media (min-width: 650px) {
  .key-features__text-outer {
    grid-template-columns: 1fr 1fr;
  }
}
@media (min-width: 768px) {
  .key-features__text-outer {
    display: block;
    flex: 1;
  }
}

.key-features__text {
  position: relative;
  padding: 2.5rem;
  background-color: white;
  border-radius: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.key-features__text .std-content, .key-features__text .woocommerce-customer-details,
.key-features__text .woocommerce-Message {
  position: relative;
  z-index: 1;
}
@media (min-width: 768px) {
  .key-features__text {
    display: none;
  }
}
@media (min-width: 1280px) {
  .key-features__text {
    padding: 5rem 2.5rem;
  }
}
@media (min-width: 768px) {
  .key-features__text.is-visible {
    display: block;
  }
}

.key-features__marker {
  position: absolute;
  top: -0.5rem;
  left: -0.5rem;
  z-index: 2;
  display: grid;
  place-items: center;
  width: 2rem;
  height: 2rem;
  border-radius: 100%;
  background-color: #ef7d43;
}
.key-features__marker svg {
  width: 1rem;
  height: 1rem;
  fill: white;
}

.key-features__img-outer {
  display: none;
}
@media (min-width: 768px) {
  .key-features__img-outer {
    flex: 1.5;
    position: relative;
    display: block;
  }
}
@media (min-width: 1280px) {
  .key-features__img-outer {
    flex: 2;
  }
}

.key-features__img {
  position: relative;
  z-index: 1;
  display: block;
  width: 100%;
  height: auto;
  opacity: 0.25;
}
@media (min-width: 1280px) {
  .key-features__img {
    opacity: 0.5;
  }
}

.key-features__hotspots {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
}

.key-features__hotspot {
  position: absolute;
  display: grid;
  place-items: center;
  width: 2rem;
  height: 2rem;
  border: none;
  border-radius: 100%;
  background-color: #ef7d43;
}
@media (min-width: 1280px) {
  .key-features__hotspot {
    width: 3rem;
    height: 3rem;
  }
}
.key-features__hotspot svg {
  width: 1rem;
  height: 1rem;
  fill: white;
}
@media (min-width: 1280px) {
  .key-features__hotspot svg {
    width: 1.5rem;
    height: 1.5rem;
  }
}
.key-features__hotspot:hover {
  background-color: #1d242b;
}
.key-features__hotspot.is-selected {
  background-color: #1d242b;
}

@media (min-width: 650px) {
  .specification__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.specification__header .btn-sm, .specification__header .product .add_to_cart_button, .product .specification__header .add_to_cart_button, .specification__header .woocommerce-message a, .woocommerce-message .specification__header a {
  margin: 2.5rem 0 0;
}
@media (min-width: 650px) {
  .specification__header .btn-sm, .specification__header .product .add_to_cart_button, .product .specification__header .add_to_cart_button, .specification__header .woocommerce-message a, .woocommerce-message .specification__header a {
    margin: 0;
  }
}

.specification-list {
  display: grid;
  grid-gap: 2.5rem;
  list-style-type: none;
}
@media (min-width: 650px) {
  .specification-list {
    grid-template-columns: 1fr 1fr;
  }
}

.specification-list__item {
  padding: 2.5rem;
  background-color: #f5f5f5;
  border-radius: 1rem;
}

.text-hero-outer {
  position: relative;
  padding: 5rem 0;
  background-color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}
@media (min-width: 768px) {
  .text-hero-outer {
    padding: 10rem 0;
  }
}
.text-hero-outer .inner {
  position: relative;
  z-index: 2;
}
.text-hero-outer:before {
  content: "";
  position: absolute;
  bottom: -20vw;
  right: -40vw;
  z-index: 1;
  width: 60vw;
  padding-bottom: 60vw;
  background: linear-gradient(90deg, #fab500 0%, #ef7d43 100%);
  border-radius: 100%;
}

.text-hero {
  display: grid;
  grid-gap: 2.5rem;
  justify-items: center;
}
@media (min-width: 1280px) {
  .text-hero {
    grid-gap: 5rem;
  }
}
.text-hero .title, .text-hero .woocommerce-order-details__title, .text-hero .woocommerce-checkout > h3, .text-hero .cart-collaterals h2, .cart-collaterals .text-hero h2, .text-hero .product .related > h2, .product .text-hero .related > h2, .text-hero .summary > h2, .text-hero .woocommerce-loop-product__link h2, .woocommerce-loop-product__link .text-hero h2,
.text-hero .supporting-text {
  text-align: center;
}
.text-hero .btn-group {
  justify-content: center;
}

.testimonials .tns-outer {
  background-color: white;
  border-radius: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

@media (min-width: 768px) {
  .testimonial {
    vertical-align: middle !important;
  }
}

.testimonial__content {
  display: grid;
  grid-gap: 2.5rem;
  padding: 2.5rem;
}
@media (min-width: 768px) {
  .testimonial__content {
    grid-template-columns: 10rem 1fr;
    padding: 5rem 10rem;
  }
}
@media (min-width: 1280px) {
  .testimonial__content {
    grid-template-columns: 15rem 1fr;
    grid-gap: 10rem;
  }
}

.testimonial__logo {
  display: block;
  width: 10rem;
  height: auto;
}
@media (min-width: 1280px) {
  .testimonial__logo {
    width: 15rem;
  }
}

.testimonial__quote {
  margin: 0 0 1.6666666667rem;
  font-family: interstate, Arial Bold, sans-serif;
  font-size: 1.8rem;
  color: #ef7d43;
  line-height: 1.2;
  letter-spacing: -0.1rem;
}
@media (min-width: 1280px) {
  .testimonial__quote {
    font-size: 2.1rem;
  }
}
.testimonial__quote:before {
  content: open-quote;
}
.testimonial__quote:after {
  content: close-quote;
}

.staff-list {
  list-style-type: none;
}

.staff {
  display: grid;
  grid-gap: 2.5rem;
}
@media (min-width: 650px) {
  .staff {
    grid-template-columns: 1fr 3fr;
  }
}
@media (min-width: 1280px) {
  .staff {
    grid-gap: 5rem;
  }
}

.staff + .staff {
  padding: 2.5rem 0 0;
  margin: 2.5rem 0 0;
  border-top: 1px solid #76868b;
}
@media (min-width: 1280px) {
  .staff + .staff {
    padding: 5rem 0 0;
    margin: 5rem 0 0;
  }
}

.staff-photo {
  display: block;
  width: 100%;
  height: auto;
  border-radius: 0.5rem;
}

.vacancies {
  list-style-type: none;
}

.vacancy + .vacancy {
  padding: 2.5rem 0 0;
  margin: 2.5rem 0 0;
  border-top: 1px solid #76868b;
}
@media (min-width: 1280px) {
  .vacancy + .vacancy {
    padding: 5rem 0 0;
    margin: 5rem 0 0;
  }
}

@media (min-width: 650px) {
  .dealers-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.dealers-header .title, .dealers-header .woocommerce-order-details__title, .dealers-header .woocommerce-checkout > h3, .dealers-header .cart-collaterals h2, .cart-collaterals .dealers-header h2, .dealers-header .product .related > h2, .product .dealers-header .related > h2, .dealers-header .summary > h2, .dealers-header .woocommerce-loop-product__link h2, .woocommerce-loop-product__link .dealers-header h2 {
  margin: 0 0 1.6666666667rem;
}
@media (min-width: 650px) {
  .dealers-header .title, .dealers-header .woocommerce-order-details__title, .dealers-header .woocommerce-checkout > h3, .dealers-header .cart-collaterals h2, .cart-collaterals .dealers-header h2, .dealers-header .product .related > h2, .product .dealers-header .related > h2, .dealers-header .summary > h2, .dealers-header .woocommerce-loop-product__link h2, .woocommerce-loop-product__link .dealers-header h2 {
    margin: 0;
  }
}

.dealer-group + .dealer-group {
  padding: 2.5rem 0 0;
  margin: 2.5rem 0 0;
  border-top: 1px solid white;
}
@media (min-width: 768px) {
  .dealer-group + .dealer-group {
    padding: 5rem 0 0;
    margin: 5rem 0 0;
  }
}

.dealer-feed {
  display: grid;
  grid-gap: 2.5rem;
  list-style-type: none;
}
@media (min-width: 650px) {
  .dealer-feed {
    grid-template-columns: 1fr 1fr;
  }
}

.dealer {
  padding: 2.5rem;
  background-color: white;
  border-radius: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.dealer__header {
  margin: 0 0 2.5rem;
}
@media (min-width: 768px) {
  .dealer__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.dealer__logo {
  display: block;
  width: 12rem;
  height: 6rem;
  margin: 0 0 2.5rem;
  object-fit: contain;
  object-position: left center;
}
@media (min-width: 768px) {
  .dealer__logo {
    margin: 0;
  }
}

.dealer__locations {
  display: flex;
  gap: 0.5rem;
  list-style-type: none;
}
.no-flexbox-gap .dealer__locations {
  margin: -0.25rem;
}
.no-flexbox-gap .dealer__locations > * {
  padding: 0.25rem;
}

.dealer__location__flag {
  display: block;
  width: 4rem;
  height: auto;
}

.dealer__address {
  margin: 0 0 2.5rem;
}
.dealer__address a {
  color: black;
}
.dealer__address a:hover {
  color: #ef7d43;
}

.manuals-header {
  padding: 0 0 2.5rem;
  margin: 0 0 2.5rem;
  border-bottom: 1px solid white;
}
@media (min-width: 768px) {
  .manuals-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.manuals-header .title, .manuals-header .woocommerce-order-details__title, .manuals-header .woocommerce-checkout > h3, .manuals-header .cart-collaterals h2, .cart-collaterals .manuals-header h2, .manuals-header .product .related > h2, .product .manuals-header .related > h2, .manuals-header .summary > h2, .manuals-header .woocommerce-loop-product__link h2, .woocommerce-loop-product__link .manuals-header h2 {
  margin: 0 0 1.6666666667rem;
}
@media (min-width: 768px) {
  .manuals-header .title, .manuals-header .woocommerce-order-details__title, .manuals-header .woocommerce-checkout > h3, .manuals-header .cart-collaterals h2, .cart-collaterals .manuals-header h2, .manuals-header .product .related > h2, .product .manuals-header .related > h2, .manuals-header .summary > h2, .manuals-header .woocommerce-loop-product__link h2, .woocommerce-loop-product__link .manuals-header h2 {
    margin: 0;
  }
}
.manuals-header .site-search {
  background-color: white;
}
@media (min-width: 768px) {
  .manuals-header .site-search {
    width: 30rem;
  }
}

.manual-filters {
  padding: 0 0 2.5rem;
  margin: 0 0 2.5rem;
  border-bottom: 1px solid white;
}
@media (min-width: 768px) {
  .manual-filters {
    display: flex;
    align-items: center;
  }
}
@media (min-width: 1280px) {
  .manual-filters {
    margin: 0 0 5rem;
  }
}
.manual-filters .title, .manual-filters .woocommerce-order-details__title, .manual-filters .woocommerce-checkout > h3, .manual-filters .cart-collaterals h2, .cart-collaterals .manual-filters h2, .manual-filters .product .related > h2, .product .manual-filters .related > h2, .manual-filters .summary > h2, .manual-filters .woocommerce-loop-product__link h2, .woocommerce-loop-product__link .manual-filters h2 {
  margin: 0 0 1.6666666667rem;
}
@media (min-width: 768px) {
  .manual-filters .title, .manual-filters .woocommerce-order-details__title, .manual-filters .woocommerce-checkout > h3, .manual-filters .cart-collaterals h2, .cart-collaterals .manual-filters h2, .manual-filters .product .related > h2, .product .manual-filters .related > h2, .manual-filters .summary > h2, .manual-filters .woocommerce-loop-product__link h2, .woocommerce-loop-product__link .manual-filters h2 {
    margin: 0 1.6666666667rem 0 0;
  }
}

.manuals-feed {
  display: grid;
  grid-gap: 5rem;
  list-style-type: none;
}
@media (min-width: 650px) {
  .manuals-feed {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 1280px) {
  .manuals-feed {
    grid-gap: 10rem;
  }
}

@media (min-width: 768px) {
  .manual-card {
    display: grid;
    grid-gap: 2.5rem;
    grid-template-columns: 1fr 4fr;
  }
}

.manual-card__img-outer {
  display: none;
}
@media (min-width: 650px) {
  .manual-card__img-outer {
    display: block;
    height: 15rem;
    margin: 0 0 2.5rem;
  }
}
@media (min-width: 768px) {
  .manual-card__img-outer {
    height: auto;
    margin: 0;
  }
}

.manual-card__img {
  display: block;
  width: auto;
  height: 100%;
}
@media (min-width: 768px) {
  .manual-card__img {
    width: 100%;
    height: auto;
  }
}

.manual-card__info {
  position: relative;
  padding: 0 0 5rem;
}
@media (min-width: 768px) {
  .manual-card__info {
    padding: 0.25rem 0 5rem;
  }
}
.manual-card__info > .btn-xs, .woocommerce-Address-title .manual-card__info > a, .woocommerce-orders-table__cell-order-actions .manual-card__info > a, .product-info .posted_in .manual-card__info > a {
  position: absolute;
  bottom: 0.25rem;
  left: 0;
  z-index: 1;
}

.head-office-info {
  display: grid;
  grid-gap: 2.5rem;
}
@media (min-width: 768px) {
  .head-office-info {
    grid-template-columns: repeat(3, 1fr);
  }
}

.form-row + .form-row {
  margin: 2.5rem 0 0;
}

.form-row__label, .woocommerce-form-row label, .wc_payment_methods .payment_box label {
  display: block;
  width: 100%;
  margin: 0 0 1rem;
  color: #1d242b;
  line-height: 1.5;
}

.form-row input[type=text],
.form-row input[type=number],
.form-row input[type=url],
.form-row input[type=password],
.form-row input[type=email],
.form-row input[type=tel],
.form-row input[type=date],
.form-row select {
  display: block;
  width: 100%;
  height: 6rem;
  padding: 0 1rem;
  border: 2px solid #76868b;
  border-radius: 0.5rem;
  font-size: 1.8rem;
  font-family: soleto, Arial, sans-serif;
}
.form-row input[type=text]:focus,
.form-row input[type=number]:focus,
.form-row input[type=url]:focus,
.form-row input[type=password]:focus,
.form-row input[type=email]:focus,
.form-row input[type=tel]:focus,
.form-row input[type=date]:focus,
.form-row select:focus {
  outline: none;
  border-color: #ef7d43;
}

.form-row input[type=date] {
  text-transform: uppercase;
}

.form-row textarea {
  display: block;
  width: 100%;
  height: 30rem;
  padding: 1rem;
  border: 2px solid #76868b;
  border-radius: 0.5rem;
  font-size: 1.8rem;
  font-family: soleto, Arial, sans-serif;
}
.form-row textarea:focus {
  outline: none;
  border-color: #ef7d43;
}

.form-row__gdpr {
  padding: 2.5rem;
  border-radius: 1rem;
  background-color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-size: 1.4rem;
}
.form-row__gdpr a {
  color: #1d242b;
}
.form-row__gdpr a:hover {
  color: black;
}

.screen-reader-response {
  display: none;
}

.wpcf7-not-valid {
  margin: 0 0 1rem;
}

span.wpcf7-not-valid {
  display: block;
  margin: 0 0 1rem;
}

.wpcf7-not-valid-tip {
  display: block;
  padding: 1rem;
  background-color: #DB0000;
  border-radius: 0.5rem;
  color: white;
}

.wpcf7-response-output {
  display: block;
  padding: 1rem;
  border-radius: 0.5rem;
  color: white;
}

.wpcf7-form.invalid .wpcf7-response-output {
  margin: 2.5rem 0 0;
  background-color: #DB0000;
}

.wpcf7-form.sent .wpcf7-response-output {
  margin: 2.5rem 0 0;
  background-color: #55a630;
}

.wpcf7-radio {
  display: block;
}

.wpcf7-list-item {
  display: block;
}
.wpcf7-list-item input[type=radio],
.wpcf7-list-item input[type=checkbox] {
  margin: 0 1rem 0 0;
}

.wpcf7-list-item + .wpcf7-list-item {
  margin: 1.6666666667rem 0 0;
}

.wpcf7-acceptance label {
  display: flex;
}

.wpcf7-acceptance input[type=checkbox] {
  margin: 0.5rem 1rem 0 0;
}

.wpcf7-submit {
  border: none;
  cursor: pointer;
}

.portal-header {
  padding: 2.5rem 0;
  margin: 0 0 5rem;
  border-bottom: 1px solid #f5f5f5;
}
@media (min-width: 768px) {
  .portal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5rem 0 2.5rem;
  }
}

.portal-header__logo {
  display: block;
  width: 12rem;
  height: 6rem;
  object-fit: contain;
  object-position: right center;
}

.woocommerce img.portal-header__logo,
.woocommerce-page img.portal-header__logo {
  height: 6rem;
}

.portal-dashboard {
  display: flex;
  gap: 2.5rem;
  justify-content: center;
  flex-wrap: wrap;
  list-style-type: none;
}
@media (min-width: 650px) {
  .portal-dashboard li {
    width: calc(50% - 1.6666666667rem);
  }
}
@media (min-width: 768px) {
  .portal-dashboard li {
    width: calc(33.333% - 1.6666666667rem);
  }
}

.portal-dashboard__link {
  display: grid;
  grid-gap: 5rem;
  justify-items: center;
  padding: 2.5rem;
  font-family: interstate, Arial Bold, sans-serif;
  font-size: 1.8rem;
  letter-spacing: -0.15rem;
  color: #1d242b;
  text-decoration: none;
  background-color: white;
  border-radius: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: 0.1s all ease-out;
}
.portal-dashboard__link:hover {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}
.portal-dashboard__link img {
  display: block;
  width: 50%;
  height: auto;
}

.file-list {
  list-style-type: none;
}
.file-list li {
  display: flex;
  justify-content: space-between;
}
.file-list li + li {
  margin: 1.6666666667rem 0 0;
  padding: 1.6666666667rem 0 0;
  border-top: 1px solid #f5f5f5;
}

.file-list__label {
  margin: 0.5rem 0 0;
}

.tns-outer {
  padding: 0 !important;
}

.tns-outer [hidden] {
  display: none !important;
}

.tns-outer [aria-controls], .tns-outer [data-action] {
  cursor: pointer;
}

.tns-slider {
  -webkit-transition: all 0s;
  -moz-transition: all 0s;
  transition: all 0s;
}

.tns-slider > .tns-item {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.tns-horizontal.tns-subpixel {
  white-space: nowrap;
}

.tns-horizontal.tns-subpixel > .tns-item {
  display: inline-block;
  vertical-align: top;
  white-space: normal;
}

.tns-horizontal.tns-no-subpixel:after {
  content: "";
  display: table;
  clear: both;
}

.tns-horizontal.tns-no-subpixel > .tns-item {
  float: left;
}

.tns-horizontal.tns-carousel.tns-no-subpixel > .tns-item {
  margin-right: -100%;
}

.tns-no-calc {
  position: relative;
  left: 0;
}

.tns-gallery {
  position: relative;
  left: 0;
  min-height: 1px;
}

.tns-gallery > .tns-item {
  position: absolute;
  left: -100%;
  -webkit-transition: transform 0s, opacity 0s;
  -moz-transition: transform 0s, opacity 0s;
  transition: transform 0s, opacity 0s;
}

.tns-gallery > .tns-slide-active {
  position: relative;
  left: auto !important;
}

.tns-gallery > .tns-moving {
  -webkit-transition: all 0.25s;
  -moz-transition: all 0.25s;
  transition: all 0.25s;
}

.tns-autowidth {
  display: inline-block;
}

.tns-lazy-img {
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  transition: opacity 0.6s;
  opacity: 0.6;
}

.tns-lazy-img.tns-complete {
  opacity: 1;
}

.tns-ah {
  -webkit-transition: height 0s;
  -moz-transition: height 0s;
  transition: height 0s;
}

.tns-ovh {
  overflow: hidden;
}

.tns-visually-hidden {
  position: absolute;
  left: -10000em;
}

.tns-transparent {
  opacity: 0;
  visibility: hidden;
}

.tns-fadeIn {
  opacity: 1;
  filter: alpha(opacity=100);
  z-index: 0;
}

.tns-normal, .tns-fadeOut {
  opacity: 0;
  filter: alpha(opacity=0);
  z-index: -1;
}

.tns-vpfix {
  white-space: nowrap;
}

.tns-vpfix > div, .tns-vpfix > li {
  display: inline-block;
}

.tns-t-subp2 {
  margin: 0 auto;
  width: 310px;
  position: relative;
  height: 10px;
  overflow: hidden;
}

.tns-t-ct {
  width: 2333.3333333%;
  width: -webkit-calc(100% * 70 / 3);
  width: -moz-calc(100% * 70 / 3);
  width: 2333.3333333333%;
  position: absolute;
  right: 0;
}

.tns-t-ct:after {
  content: "";
  display: table;
  clear: both;
}

.tns-t-ct > div {
  width: 1.4285714%;
  width: -webkit-calc(100% / 70);
  width: -moz-calc(100% / 70);
  width: 1.4285714286%;
  height: 10px;
  float: left;
}

/*# sourceMappingURL=sourcemaps/tiny-slider.css.map */
.login-form {
  padding: 2.5rem;
  background-color: white;
  border-radius: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.tml-field-wrap + .tml-field-wrap {
  margin: 2.5rem 0 0;
}

.tml-label {
  display: block;
  width: 100%;
  margin: 0 0 1rem;
  color: #1d242b;
  line-height: 1.5;
}

.tml-field-wrap input[type=text],
.tml-field-wrap input[type=number],
.tml-field-wrap input[type=url],
.tml-field-wrap input[type=password],
.tml-field-wrap input[type=email],
.tml-field-wrap input[type=tel],
.tml-field-wrap input[type=date],
.tml-field-wrap select {
  display: block;
  width: 100%;
  height: 6rem;
  padding: 0 1rem;
  border: 2px solid #76868b;
  border-radius: 0.5rem;
  font-size: 1.8rem;
  font-family: soleto, Arial, sans-serif;
}
.tml-field-wrap input[type=text]:focus,
.tml-field-wrap input[type=number]:focus,
.tml-field-wrap input[type=url]:focus,
.tml-field-wrap input[type=password]:focus,
.tml-field-wrap input[type=email]:focus,
.tml-field-wrap input[type=tel]:focus,
.tml-field-wrap input[type=date]:focus,
.tml-field-wrap select:focus {
  outline: none;
  border-color: #ef7d43;
}

.tml-rememberme-wrap {
  margin: 2.5rem 0 0;
}
.tml-rememberme-wrap label {
  display: inline;
  margin: 0;
}

.tml-login .tml-alerts,
.tml-lostpassword .tml-alerts {
  margin: 0 0 2.5rem;
}

.tml-errors {
  padding: 1.6666666667rem;
  list-style-type: none;
  background-color: #DB0000;
  border-radius: 0.5rem;
}

.tml-error {
  color: white;
}

.tml-error + .tml-error {
  margin: 1rem 0 0;
}

.tml-links {
  display: flex;
  flex-wrap: wrap;
  gap: 1.6666666667rem;
  margin: 5rem 0 0;
  font-size: 1.4rem;
  list-style-type: none;
}
.tml-links a {
  color: #1d242b;
}
.tml-links a:hover {
  color: #ef7d43;
}

.no-flexbox-gap .tml-links li + .tml-links li {
  margin: 1.6666666667rem 0 0;
}

.tml-messages {
  padding: 1.6666666667rem;
  list-style-type: none;
  background-color: #55a630;
  border-radius: 0.5rem;
}

.tml-message {
  color: white;
}
.tml-message a {
  color: inherit;
}

.tml-message + .tml-message {
  margin: 1rem 0 0;
}

.tml-register-link {
  display: none;
}

.woocommerce-notices-wrapper {
  width: 100%;
}

.woocommerce-message {
  display: flex;
  justify-content: space-between;
  padding: 1.6666666667rem;
  margin: 0 0 2.5rem;
  border-radius: 0.5rem;
  background-color: #ef7d43;
  color: white;
}
.woocommerce-message a {
  flex-shrink: 0;
  order: 2;
  margin: 0 0 0 2.5rem;
}

@media (min-width: 650px) {
  .product-grid-filters {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }
}
.product-grid-filters .woocommerce-result-count {
  font-size: 1.4rem;
  color: #1d242b;
}
.product-grid-filters .woocommerce-result-count:before {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 0.5rem 0 0;
  border-radius: 100%;
  background-color: #ef7d43;
}
.product-grid-filters .woocommerce-ordering select {
  display: block;
  width: 100%;
  height: 3rem;
  padding: 0 0.5rem;
  border: 2px solid #76868b;
  border-radius: 0.5rem;
  font-size: 1.4rem;
  font-family: soleto, Arial, sans-serif;
}
.product-grid-filters .woocommerce-ordering select:focus {
  outline: none;
  border-color: #ef7d43;
}

.products.columns-4 {
  display: grid;
  grid-gap: 1.6666666667rem;
  margin: 0 0 5rem;
  list-style-type: none;
}
@media (min-width: 650px) {
  .products.columns-4 {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 768px) {
  .products.columns-4 {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (min-width: 1280px) {
  .products.columns-4 {
    grid-template-columns: repeat(4, 1fr);
  }
}

.woocommerce-loop-product__link {
  display: block;
  height: 100%;
  padding: 2.5rem 2.5rem 10rem;
  border-radius: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: white;
  text-decoration: none;
}
.woocommerce-loop-product__link img {
  display: block;
  width: 100%;
  height: 20rem;
  object-fit: contain;
  object-position: center center;
  margin: 0 0 2.5rem;
}
@media (min-width: 1024px) {
  .woocommerce-loop-product__link img {
    height: 25rem;
  }
}
.woocommerce-loop-product__link h2 {
  margin: 0 0 1rem;
  text-decoration: underline;
}
.woocommerce-loop-product__link .price {
  font-family: interstate, Arial Bold, sans-serif;
  color: #76868b;
}
.woocommerce-loop-product__link:hover h2 {
  color: #ef7d43;
}

.product {
  position: relative;
}
.product .add_to_cart_button {
  position: absolute;
  left: 2.5rem;
  bottom: 2.5rem;
  z-index: 1;
}

.woocommerce-pagination .page-numbers {
  font-family: interstate, Arial Bold, sans-serif;
  font-size: 1.8rem;
}
.woocommerce-pagination ul.page-numbers {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  list-style-type: none;
}
.woocommerce-pagination ul.page-numbers li {
  margin: 0 1rem;
}
.woocommerce-pagination ul.page-numbers li:not(:first-child):not(:last-child) {
  display: none;
}
@media (min-width: 768px) {
  .woocommerce-pagination ul.page-numbers li:not(:first-child):not(:last-child) {
    display: block;
  }
}
.woocommerce-pagination a.page-numbers {
  display: block;
  padding: 1rem;
  color: #1d242b;
}
.woocommerce-pagination a.page-numbers:hover {
  color: #ef7d43;
}
.woocommerce-pagination span.page-numbers.current {
  color: #ef7d43;
}

.main-content--portal .product-hero-outer {
  padding: 5rem 0;
}
@media (min-width: 768px) {
  .main-content--portal .product-hero-outer {
    padding: 5rem 0 10rem;
  }
}
.main-content--portal .product-hero-outer:before {
  top: 0;
}

@media (min-width: 768px) {
  .portal-gallery-outer {
    order: 1;
  }
}

.portal-gallery__img-outer {
  width: 100%;
}
@media (min-width: 768px) {
  .portal-gallery__img-outer {
    aspect-ratio: 3/2;
  }
  @supports not (aspect-ratio: 3/2) {
    .portal-gallery__img-outer:before {
      float: left;
      padding-top: 66.6666666667%;
      content: "";
    }
    .portal-gallery__img-outer:after {
      display: block;
      content: "";
      clear: both;
    }
  }
}

.portal-gallery__img {
  display: block;
  width: 100%;
  height: 30rem;
  object-fit: contain;
}
@media (min-width: 768px) {
  .portal-gallery__img {
    height: 40rem;
  }
}

.summary .price,
.summary #product_total_price {
  font-size: 2.1rem;
}

.product-info .cart {
  padding: 2.5rem;
  background-color: white;
  border-radius: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.product-info .product_meta > span {
  display: block;
}
.product-info .product_meta .sku_wrapper {
  margin: 0 0 2.5rem;
}
.add-to-cart-actions {
  display: flex;
}
.add-to-cart-actions .quantity {
  width: 15rem;
  margin: 0 1.6666666667rem 0 0;
}
.add-to-cart-actions .qty {
  display: block;
  width: 100%;
  height: 4rem;
  padding: 0 1rem;
  border: 2px solid #76868b;
  border-radius: 0.5rem;
  font-size: 1.8rem;
  font-family: soleto, Arial, sans-serif;
}
.add-to-cart-actions .qty:focus {
  outline: none;
  border-color: #ef7d43;
}
.cart-cols {
  display: grid;
  grid-gap: 5rem;
}
@media (min-width: 1024px) {
  .cart-cols {
    grid-template-columns: 2.5fr 1fr;
  }
}

.woocommerce-cart-form > table,
.woocommerce-orders-table {
  width: 100%;
  border-top: 2px solid #1d242b;
}
@media (min-width: 650px) {
  .woocommerce-cart-form > table,
.woocommerce-orders-table {
    border: none;
  }
}
.woocommerce-cart-form > table thead,
.woocommerce-orders-table thead {
  display: none;
}
@media (min-width: 650px) {
  .woocommerce-cart-form > table thead,
.woocommerce-orders-table thead {
    display: table-header-group;
  }
}
.woocommerce-cart-form > table th,
.woocommerce-orders-table th {
  padding: 0 0 0.5rem;
  font-family: interstate, Arial Bold, sans-serif;
  font-size: 1.8rem;
  color: #1d242b;
  letter-spacing: -0.1rem;
  border-bottom: 2px solid #1d242b;
}
.woocommerce-cart-form > table td,
.woocommerce-orders-table td {
  display: block;
  padding: 1rem 0;
  border-bottom: 1px solid #f5f5f5;
}
@media (min-width: 650px) {
  .woocommerce-cart-form > table td,
.woocommerce-orders-table td {
    display: table-cell;
    padding: 1rem 1rem 1rem 0;
  }
}
@media (min-width: 650px) {
  .woocommerce-cart-form > table td:last-child,
.woocommerce-orders-table td:last-child {
    text-align: right;
    padding: 1rem 0;
  }
}
.woocommerce-cart-form > table td.product-name a,
.woocommerce-orders-table td.product-name a {
  color: #1d242b;
}
.woocommerce-cart-form > table td.product-name a:hover,
.woocommerce-orders-table td.product-name a:hover {
  color: #ef7d43;
}
.woocommerce-cart-form > table .qty,
.woocommerce-orders-table .qty {
  display: block;
  width: 10rem;
  height: 4rem;
  padding: 0 1rem;
  border: 2px solid #76868b;
  border-radius: 0.5rem;
  font-size: 1.8rem;
  font-family: soleto, Arial, sans-serif;
}
.woocommerce-cart-form > table .qty:focus,
.woocommerce-orders-table .qty:focus {
  outline: none;
  border-color: #ef7d43;
}
.woocommerce-cart-form > table .cart-mob-label,
.woocommerce-orders-table .cart-mob-label {
  font-family: interstate, Arial Bold, sans-serif;
  letter-spacing: -0.1rem;
}
@media (min-width: 650px) {
  .woocommerce-cart-form > table .cart-mob-label,
.woocommerce-orders-table .cart-mob-label {
    display: none;
  }
}

.cart-collaterals {
  padding: 2.5rem;
  background-color: white;
  border-radius: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.cart-collaterals table {
  margin: 0 0 2.5rem;
  font-size: 1.4rem;
}
.cart-collaterals table th {
  width: 30%;
  padding: 0.5rem 0.5rem 0.5rem 0;
  font-family: interstate, Arial Bold, sans-serif;
  color: #1d242b;
  letter-spacing: -0.1rem;
  vertical-align: top;
}
.cart-collaterals table td {
  width: 70%;
  padding: 0.5rem 0;
  vertical-align: top;
}
.cart-collaterals .woocommerce-shipping-destination {
  margin: 0 0 1rem;
}
.cart-collaterals .woocommerce-shipping-calculator a {
  color: #1d242b;
}
.cart-collaterals .woocommerce-shipping-calculator a:hover {
  color: black;
}
.woocommerce-shipping-methods {
  margin: 0 0 1rem;
  list-style-type: none;
}
.woocommerce-shipping-methods li + li {
  margin: 0.25rem 0 0;
}

.cart-subtotal .woocommerce-Price-amount,
.order-total .woocommerce-Price-amount {
  color: #1d242b;
}

#customer_details {
  display: none;
}

.woocommerce-checkout-review-order > table,
.woocommerce-table--order-details {
  width: 100%;
  border-top: 2px solid #1d242b;
}
@media (min-width: 650px) {
  .woocommerce-checkout-review-order > table,
.woocommerce-table--order-details {
    border: none;
  }
}
.woocommerce-checkout-review-order > table thead,
.woocommerce-table--order-details thead {
  display: none;
}
@media (min-width: 650px) {
  .woocommerce-checkout-review-order > table thead,
.woocommerce-table--order-details thead {
    display: table-header-group;
  }
}
.woocommerce-checkout-review-order > table thead th,
.woocommerce-table--order-details thead th {
  padding: 0 0 0.5rem;
  font-family: interstate, Arial Bold, sans-serif;
  font-size: 1.8rem;
  color: #1d242b;
  letter-spacing: -0.1rem;
  border-bottom: 2px solid #1d242b;
}
.woocommerce-checkout-review-order > table tfoot tr:first-child th,
.woocommerce-checkout-review-order > table tfoot tr:first-child td,
.woocommerce-table--order-details tfoot tr:first-child th,
.woocommerce-table--order-details tfoot tr:first-child td {
  border-top: 2px solid #f5f5f5;
}
.woocommerce-checkout-review-order > table tfoot th,
.woocommerce-table--order-details tfoot th {
  font-family: interstate, Arial Bold, sans-serif;
  font-size: 1.8rem;
  color: #1d242b;
  letter-spacing: -0.1rem;
}
.woocommerce-checkout-review-order > table th, .woocommerce-checkout-review-order > table td,
.woocommerce-table--order-details th,
.woocommerce-table--order-details td {
  vertical-align: top;
}
.woocommerce-checkout-review-order > table td, .woocommerce-checkout-review-order > table th,
.woocommerce-table--order-details td,
.woocommerce-table--order-details th {
  padding: 1rem 1rem 1rem 0;
  border-bottom: 1px solid #f5f5f5;
}
@media (min-width: 650px) {
  .woocommerce-checkout-review-order > table td:last-child,
.woocommerce-table--order-details td:last-child {
    text-align: right;
    padding: 1rem 0;
  }
}
.woocommerce-checkout-review-order > table td.product-name a,
.woocommerce-table--order-details td.product-name a {
  color: #1d242b;
}
.woocommerce-checkout-review-order > table td.product-name a:hover,
.woocommerce-table--order-details td.product-name a:hover {
  color: #ef7d43;
}

.woocommerce-checkout-payment {
  padding: 2.5rem;
  background-color: white;
  border-radius: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.wc_payment_methods {
  list-style-type: none;
}
.wc_payment_methods .payment_box {
  margin: 1.6666666667rem 0 0;
}
.woocommerce-privacy-policy-text {
  font-size: 1.4rem;
  line-height: 1.5;
}
.woocommerce-privacy-policy-text a {
  color: #1d242b;
}
.woocommerce-privacy-policy-text a:hover {
  color: black;
}

.my-account {
  display: grid;
  grid-gap: 2.5rem;
}
@media (min-width: 768px) {
  .my-account {
    grid-template-columns: 1fr 3fr;
  }
}

.woocommerce-MyAccount-content > h2 {
  margin: 0 0 2.5rem;
  font-family: interstate, Arial Bold, sans-serif;
  line-height: 1.2;
  color: #1d242b;
  letter-spacing: -0.15rem;
}
.woocommerce-MyAccount-content > p {
  line-height: 1.5;
}
.woocommerce-MyAccount-content > p a {
  color: black;
  text-decoration: underline;
}
.woocommerce-MyAccount-content > p a:hover {
  color: #ef7d43;
}
.woocommerce-MyAccount-content > p > strong {
  color: black;
}
.woocommerce-MyAccount-content > p > em {
  font-style: italic;
}

.woocommerce-MyAccount-navigation ul {
  font-size: 1.8rem;
  list-style-type: none;
}
.woocommerce-MyAccount-navigation li + li {
  margin: 1rem 0 0;
}
.woocommerce-MyAccount-navigation li.is-active a {
  color: #ef7d43;
  text-decoration: underline;
}
.woocommerce-MyAccount-navigation a {
  color: #1d242b;
  text-decoration: none;
}
.woocommerce-MyAccount-navigation a:hover {
  color: #ef7d43;
}

.woocommerce-orders-table__cell-order-number a {
  color: #1d242b;
}
.woocommerce-orders-table__cell-order-number a:hover {
  color: black;
}

.woocommerce-orders-table__cell-order-actions a {
  margin: 0.5rem;
}

.woocommerce-Addresses {
  margin: 2.5rem 0 0;
}
.woocommerce-Addresses h2, .woocommerce-Addresses h3 {
  margin: 0 0 1.6666666667rem;
  font-family: interstate, Arial Bold, sans-serif;
  line-height: 1.2;
  color: #1d242b;
  letter-spacing: -0.15rem;
}
.woocommerce-Addresses p {
  line-height: 1.5;
}
.woocommerce-Addresses p a {
  color: black;
  text-decoration: underline;
}
.woocommerce-Addresses p a:hover {
  color: #ef7d43;
}
.woocommerce-Addresses p > strong {
  color: black;
}
.woocommerce-Addresses p > em {
  font-style: italic;
}
.woocommerce-Addresses address {
  font-style: normal;
}

.woocommerce-Address + .woocommerce-Address {
  margin: 2.5rem 0 0;
}

.woocommerce-Address-title a {
  margin: 0 0 2.5rem;
  font-family: soleto, Arial, sans-serif;
}

.woocommerce-form-row .form-row__helper {
  display: block;
  margin: 1rem 0 0;
  font-size: 1.4rem;
}
.woocommerce-form-row .form-row__helper em {
  font-style: normal;
}

lite-youtube {
  background-color: #000;
  position: relative;
  display: block;
  contain: content;
  background-position: center center;
  background-size: cover;
  cursor: pointer;
  max-width: 100%;
}

/* gradient */
lite-youtube::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAADGCAYAAAAT+OqFAAAAdklEQVQoz42QQQ7AIAgEF/T/D+kbq/RWAlnQyyazA4aoAB4FsBSA/bFjuF1EOL7VbrIrBuusmrt4ZZORfb6ehbWdnRHEIiITaEUKa5EJqUakRSaEYBJSCY2dEstQY7AuxahwXFrvZmWl2rh4JZ07z9dLtesfNj5q0FU3A5ObbwAAAABJRU5ErkJggg==);
  background-position: top;
  background-repeat: repeat-x;
  height: 60px;
  padding-bottom: 50px;
  width: 100%;
  transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
}

/* responsive iframe with a 16:9 aspect ratio
    thanks https://css-tricks.com/responsive-iframes/
*/
lite-youtube::after {
  content: "";
  display: block;
  padding-bottom: 56.25%;
}

lite-youtube > iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border: 0;
}

/* play button */
lite-youtube > .lty-playbtn {
  display: block;
  width: 68px;
  height: 48px;
  position: absolute;
  cursor: pointer;
  transform: translate3d(-50%, -50%, 0);
  top: 50%;
  left: 50%;
  z-index: 1;
  background-color: transparent;
  /* YT's actual play button svg */
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68 48"><path d="M66.52 7.74c-.78-2.93-2.49-5.41-5.42-6.19C55.79.13 34 0 34 0S12.21.13 6.9 1.55c-2.93.78-4.63 3.26-5.42 6.19C.06 13.05 0 24 0 24s.06 10.95 1.48 16.26c.78 2.93 2.49 5.41 5.42 6.19C12.21 47.87 34 48 34 48s21.79-.13 27.1-1.55c2.93-.78 4.64-3.26 5.42-6.19C67.94 34.95 68 24 68 24s-.06-10.95-1.48-16.26z" fill="red"/><path d="M45 24 27 14v20" fill="white"/></svg>');
  filter: grayscale(100%);
  transition: filter 0.1s cubic-bezier(0, 0, 0.2, 1);
  border: none;
}

lite-youtube:hover > .lty-playbtn,
lite-youtube .lty-playbtn:focus {
  filter: none;
}

/* Post-click styles */
lite-youtube.lyt-activated {
  cursor: unset;
}

lite-youtube.lyt-activated::before,
lite-youtube.lyt-activated > .lty-playbtn {
  opacity: 0;
  pointer-events: none;
}

.lyt-visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}