.btn-xs {
  display: inline-block;
  height: 2rem;
  padding: 0 1rem;

  border-radius: 1rem;

  font-size: $font-size-xxs;
  text-decoration: none;
  line-height: 2rem;

  transition: $transition-sm;
}

.btn-sm {
  display: inline-block;
  height: 3rem;
  padding: 0 1.5rem;

  border-radius: 1.5rem;

  font-size: $font-size-xxs;
  text-decoration: none;
  line-height: 3rem;

  transition: $transition-sm;
}

.btn-lg {
  display: inline-block;
  align-items: center;
  height: 4rem;
  padding: 0 2rem;

  border-radius: 2rem;

  font-size: $font-size-xxs;
  text-decoration: none;
  line-height: 4rem;
  transition: $transition-sm;

    @include mq(screen-sm) {
      font-size: $font-size-xs;
    }

}

input.btn-lg,
input.btn-sm,
input.btn-xs {
  border: none;
}



// Colour variants

.btn--orange {
  background-color: $color-orange-dark;

  color: white;

    &:hover {
      background-color: $color-grey-dark;
    }

}

.btn--orange-fade {
  background-color: $fade-orange-15;

  color: $color-orange-dark;

    &:hover {
      background-color: $color-orange-dark;

      color: white;
    }

}


.btn--grey-dark {
  background-color: $color-grey-dark;

  color: white;

    &:hover {
      background-color: black;
    }

}

.btn--white {
  background-color: white;

  color: $color-grey-dark;

    &:hover {
      background-color: $color-grey-dark;

      color: white;
    }

}

// Button group

.btn-group {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;

  list-style-type: none;

  @include flexbox-gap-support(0.5);
}