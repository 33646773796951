.vacancies {
  list-style-type: none;
}

.vacancy + .vacancy {
  padding: $padding-md 0 0;
  margin: $padding-md 0 0;

  border-top: 1px solid $color-grey-mid;

    @include mq(screen-lg) {
      padding: $padding-lg 0 0;
      margin: $padding-lg 0 0;
    }

}