.product-feed-intro {
  max-width: $screen-sm;
  margin: 0 auto $padding-lg;
  text-align: center;
}

.product-feed {
  display: grid;
  grid-gap: $padding-sm;

  list-style-type: none;
}

.product-feed--2-col {
  
  @include mq(screen-xs) {
    grid-template-columns: repeat(2, 1fr);
  }

}

.product-feed--4-col {
  
  @include mq(screen-xs) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include mq(screen-sm) {
    grid-template-columns: repeat(3, 1fr);
  }

  @include mq(screen-lg) {
    grid-template-columns: repeat(4, 1fr);
  }

}

// Systems

.system-card,
.vehicle-fitting-card {
  border-radius: $border-radius-md;
  box-shadow: $box-shadow;
  background-color: white;

  overflow: hidden;

    @include mq(screen-md) {
      display: grid;
      grid-template-columns: 1fr 1.5fr;
      align-items: center;
      justify-items: center;
    }

}

.system-card__img-outer {
  display: block;
  height: 20rem;
  padding: $padding-md;

  border-bottom: 1px solid $color-grey-light;

    @include mq(screen-md) {
      height: 25rem;
      border: 0;
    }

}

.vehicle-fitting-card__img-outer {
  display: block;
  height: 20rem;
  padding: $padding-md;

    @include mq(screen-md) {
      height: 25rem;
    }

}

.system-card__img {
  display: block;
  width: auto;
  height: 100%;
  margin: 0 auto;
}

.vehicle-fitting-card__img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;

  border-radius: $border-radius-sm;
  overflow: hidden;
}

.system-card__info,
.vehicle-fitting-card__info {
  display: grid;
  gap: $padding-md;
  justify-items: start;
  padding: $padding-md;

    @include mq(screen-md) {
      padding: $padding-lg $padding-md;
    }

}

.system-card__logo {
  display: block;
  width: 12rem;
  height: 3rem;

  object-fit: contain;
  object-position: left center;
}

// Products

.product-card {
  display: flex;
  flex-direction: column;
  border-radius: $border-radius-md;
  box-shadow: $box-shadow;
  background-color: white;
}

.product-card__img-outer {
  display: block;
  height: 20rem;
  padding: $padding-md;

    @include mq(screen-md) {
      height: 25rem;
      padding: $padding-lg;
    }

}

.product-card__img {
  display: block;
  width: auto;
  height: 100%;
  margin: 0 auto;
}

.product-card__info {
  flex: 1;
  position: relative;
  padding: $padding-md $padding-md 10rem;

  background-color: $color-grey-light;

    > .btn-sm {
      position: absolute;
      bottom: $padding-md;
      left: $padding-md;
      z-index: 1;
    }

}

.product-id {
  font-family: $interstate;
  font-size: $font-size-xxs;
}

.product-card__related {
  // display: flex;
  // align-items: center;
  // flex-wrap: wrap;
}

.product-card__related__title {
  margin: 0 0 0.5rem;

  font-family: $interstate;
  font-size: $font-size-xxs;
  color: $color-grey-dark;
  letter-spacing: $kerning-sm;
}
