.product-category-feed {
  display: grid;
  grid-gap: $padding-md;

  padding: $padding-md;
  margin: 0 $padding-md;

  border-radius: $border-radius-lg;
  background-color: $color-grey-light;

    @include mq(screen-sm) {
      justify-items: center; 

      padding: $padding-lg;
    }

    @include mq(screen-md) {
      margin: 0 $padding-md;
    }

    @include mq(screen-lg) {
      grid-gap: $padding-lg;

      padding: $padding-lg $padding-xl;
    }

}

.product-category-feed__list {
  display: flex;
  flex-direction: column;
  gap: $padding-sm;

  list-style-type: none;

  @include flexbox-gap-support(2);

    @include mq(screen-xs) {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
    }

    @include mq(screen-lg) {
      gap: $padding-lg;
    }

    li {
     
      @include mq(screen-xs) {
        width: calc(50% - $padding-sm);
      }

      @include mq(screen-sm) {
        width: 12rem;
      }

    }

}

.product-category-feed__link {
  display: flex;
  align-items: center;

  font-size: $font-size-xs;
  text-decoration: none;
  color: $color-grey-dark;
  line-height: $line-height-sm;

    @include mq(screen-sm) {
      flex-direction: column;

      text-align: center;
    }

    &:hover {
        
      .product-category-feed__img-outer {
        box-shadow: $box-shadow-hover;
      }

    }

}

.product-category-feed__img-outer {
  display: grid;
  place-items: center;

  width: 5rem;
  height: 5rem;
  margin: 0 $padding-sm 0 0;

  background-color: white;
  border-radius: $border-radius-md;

  box-shadow: $box-shadow;

  transition: $transition-sm;

    @include mq(screen-sm) {
      width: 12rem;
      height: 12rem;
      margin: 0 0 $padding-sm;
    }

}

.product-category-feed__img {
  display: block;
  width: 60%;
  height: 60%;

  object-fit: contain;
}