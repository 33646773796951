.gallery-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.gallery-slider-outer {
  position: relative;

    > .tns-outer {
      
      @include mq(screen-sm) {
        width: calc(100% - 10rem);
        margin: 0 auto;
      }

      @media screen and (min-width: 1350px) {
        width: 100%;
      }

    }

}

.gallery-control {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;

  display: grid;
  place-items: center;
  width: 3rem;
  height: 3rem;

  border-radius: 100%;
  background-color: $color-orange-dark;

    svg {
      width: 1.5rem;
      height: 1.5rem;

      fill: white;
    }

    &:hover {
      background-color: $color-grey-dark;
    }

}

.gallery-control--prev {
  left: 1rem;

    @media screen and (min-width: 1350px) {
      left: -$padding-lg;
    }

    svg {
      transform: rotate(-180deg);
    }

}

.gallery-control--next {
  right: 1rem;

    @media screen and (min-width: 1350px) {
      right: -$padding-lg;
    }

}

.gallery {
  position: relative;
  z-index: 1;
}

.gallery__img-outer {
  @include aspect-ratio(16, 9);

  padding: 0 1rem;
}

.gallery__img {
  display: block;
  width: 100%;
  height: 100%;

  object-fit: cover;
  border-radius: $border-radius-md;
  overflow: hidden;
}