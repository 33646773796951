.video-gallery {
  display: grid;
  grid-gap: $padding-md;

    @include mq(screen-md) {
      align-items: center;
      grid-template-columns: 1fr 1fr;
      grid-gap: $padding-xl;
    }

}

.video-gallery__video {
  @include aspect-ratio(16, 9);

    iframe {
      display: block;
      width: 100%;
      height: 100%;
    }

}
