// =================================================
// LAYOUT STYLES
// =================================================

.outer {
  position: relative;
}

.inner {
  width: 100%;
  margin: 0 auto;
  padding: 0 $padding-md;

    @include mq(screen-lg) {
      padding: 0 $padding-lg;
    }

}

.inner.inner--full {
  padding: 0;
}

.inner--sm {

  @include mq(screen-sm) {
    width: $screen-sm;
  }

}

.inner--md {

  @include mq(screen-md) {
    width: $screen-md;
  }

}

.inner--lg {

  @include mq(screen-lg) {
    width: $screen-lg;
  }

}

.inner--xl {

  @include mq(screen-xl) {
    width: $screen-xl;
  }

}

.inner--xxl {
  
  @include mq(screen-xxl) {
    width: $screen-xxl;
  }

}

.main-content {
  position: relative;
  z-index: 1;
  overflow-x: hidden;
}

.main-content--portal {
  min-height: 75vh;
}

.main-content--portal .product .pg-content__section:nth-child(odd) {
  
  > div {
    @extend .bg-grey-light;
    @extend .ptb-xl;
  }

}

.pg-content__section:not(:first-child):not(:last-child):not(:only-child) {
  margin: $padding-lg 0;

    @include mq(screen-lg) {
      margin: $padding-xl 0;
    }

}

.pg-content__section:only-child,
.pg-content__section:first-child:not(:only-child),
.pg-content__section + .pg-content__section:last-child {
  
  & > .bg-white {
    padding: $padding-lg 0;

      @include mq(screen-lg) {
        padding: $padding-xl 0;
      }

  }

}

.pg-content__section:last-child,
.pg-content__section:only-child {
  
  .pg-end-ctas {
    padding: 0 0 $padding-lg;

        @include mq(screen-lg) {
          padding: 0 0 $padding-xl;
        }
  }

}