.key-features {
  
  @include mq(screen-sm) {
    // display: grid;
    // grid-gap: $padding-lg;
    // grid-template-columns: 1fr 1fr;
    // align-content: start;

    display: flex;
    align-items: flex-start;
    gap: $padding-lg;

    @include flexbox-gap-support(2);
  }

  @include mq(screen-lg) {
    //grid-template-columns: 1fr 2fr;
  }

}

.key-features__text-outer {
  display: grid;
  grid-gap: $padding-md;

    @include mq(screen-xs) {
      grid-template-columns: 1fr 1fr;
    }

    @include mq(screen-sm) {
      display: block;
      flex: 1;
    }

}

.key-features__text {
  position: relative;

  padding: $padding-md;

  background-color: white;
  border-radius: $border-radius-md;
  box-shadow: $box-shadow;

    .std-content {
      position: relative;
      z-index: 1;
    }

    @include mq(screen-sm) {
      display: none;

      // opacity: 0;
      // visibility: hidden;
    }

    @include mq(screen-lg) {
      padding: $padding-lg $padding-md;
    }

    &.is-visible {
      
      @include mq(screen-sm) {
        display: block;
        //opacity: 1;
        //visibility: visible;
      }

    }

}

.key-features__marker {
  position: absolute;
  top: -0.5rem;
  left: -0.5rem;
  z-index: 2;

  display: grid;
  place-items: center;
  width: 2rem;
  height: 2rem;

  border-radius: 100%;

  background-color: $color-orange-dark;

    svg {
      width: 1rem;
      height: 1rem;

      fill: white;
    }

}

.key-features__img-outer {
  display: none;

    @include mq(screen-sm) {
      flex: 1.5;
      position: relative;
      display: block;
    }

    @include mq(screen-lg) {
      flex: 2;
    }

}

.key-features__img {
  position: relative;
  z-index: 1;

  display: block;
  width: 100%;
  height: auto;

  opacity: 0.25;
  
    @include mq(screen-lg) {
      opacity: 0.5;
    }

}

.key-features__hotspots {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;

  width: 100%;
  height: 100%;
}

.key-features__hotspot {
  position: absolute;

  display: grid;
  place-items: center;
  width: 2rem;
  height: 2rem;

  border: none;
  border-radius: 100%;

  background-color: $color-orange-dark;

    @include mq(screen-lg) {
      width: 3rem;
      height: 3rem;
    }

    svg {
      width: 1rem;
      height: 1rem;

      fill: white;

        @include mq(screen-lg) {
          width: 1.5rem;
          height: 1.5rem;
        }

    }

    &:hover {
      background-color: $color-grey-dark;
    }

    &.is-selected {
      background-color: $color-grey-dark;
    }

}