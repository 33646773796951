.usps-media-outer {
  position: relative;

    .inner {
      position: relative;
      z-index: 2;
    }

    &:after {
      content: "";

      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 1;

      width: 100%;
      height: 50%;

      background-color: $color-grey-dark;
    }

}

.usps-media {
  width: 100%;

  border-radius: $border-radius-sm;
  overflow: hidden;

  @include aspect-ratio(16, 9);

    iframe {
      width: 100%;
      height: 100%;
    }

}

.usps-media__img {
  display: block;
  width: 100%;
  height: 100%;

  object-fit: cover;
}

.usps-grid {
  display: flex;
  flex-wrap: wrap;
  gap: $padding-md;
  justify-content: center;

  list-style-type: none;

  @include flexbox-gap-support(2);

    @include mq(screen-lg) {
      gap: $padding-lg;
    }

}

.usps-grid__item {
  max-width: 25%;

  text-align: center;
  color: white;

    p {
      font-size: inherit;
    }
    
}