.site-search {
  position: relative;

  display: flex;
  justify-content: space-between;
  align-items: center;

 
  width: 100%;
  height: 4rem;
 
  border-radius: 3px;
  background-color: $color-grey-light;

   @include mq(screen-lg) {
     height: 5rem;
   }
 
 }
 
 .site-search__text-field {
   position: relative;
   z-index: 1;

   width: 100%;
   height: 100%;
   padding: 0 5rem 0 1rem;
 
   border: none;
   background: none;
 
   font-size: $font-size-xs;
 }
 
 .site-search__btn {
   position: absolute;
   top: 50%;
   right: 1rem;
   transform: translateY(-50%);
   z-index: 2;
 
   display: grid;
   place-content: center;
   width: 3rem;
   height: 3rem;
 
   border-radius: 100%;
   background-color: $color-grey-dark;

   transition: $transition-sm;

    &:hover {
      background-color: $color-orange-dark;
    }
 
     svg {
       width: 1.5rem;
       height: 1.5rem;
 
       fill: white;
     }
 
 }

 // Header specific styles

 .main-nav-outer {
  
  .site-search {
    display: none;
   
    position: absolute;
    top: 9rem;
    left: $padding-md;
    z-index: 2;
   
    width: calc(100% - $padding-lg);
   
    background-color: white;
   
     @include mq(screen-sm) {
       top: $padding-sm;
       left: 17.5rem;
   
       display: flex;
       width: 30rem;
   
       background-color: $color-grey-light;
     }
   
     @include mq(screen-lg) {
       top: 1.5rem;
       left: 20rem;
     }
   
     &.is-visible {
       display: flex;
     }
   
   }

 }

 // Search results
 
 .search-results {
   list-style-type: none;
 }

 .search-result + .search-result {
   margin: $padding-md 0 0;
   padding: $padding-md 0 0;

   border-top: 1px solid $color-grey-light;
 }