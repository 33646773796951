.post-header {
  padding: $padding-lg 0;
  margin: 0 0 $padding-lg;

  border-bottom: 1px solid $color-grey-mid;
}

.post-meta {
  display: flex;
  flex-wrap: wrap;
  gap: $padding-sm;
}

.post-meta__author {
  font-size: $font-size-xxs;
}

.post-meta__date {
  font-size: $font-size-xxs;
  color: $color-grey-dark;
}