/*!
Theme Name: MiniCam
Theme URI: 
Author: Ride Shotgun
Author URI: http://www.rideshotgun.co.uk
Description: 
Version: 2.0
License: GNU General Public License v2 or later
License URI: http://www.gnu.org/licenses/gpl-2.0.html
Text Domain: 
Tags:
*/


@import "config/fonts";
@import "config/variables";
@import "config/mixins";
@import "config/media-queries";

@import "global/reset";
@import "global/helpers";
@import "global/layout";
@import "global/spacing";
@import "global/header";
@import "global/footer";
@import "global/typography";
@import "global/buttons";
@import "global/animations";

@import "components/std-content";
@import "components/hero";
@import "components/hero-cta";
@import "components/site-search";
@import "components/product-category-feed";
@import "components/usps";
@import "components/contact-cta";
@import "components/hero-contact-cta";
@import "components/text-img";
@import "components/pg-end-ctas";
@import "components/product-feeds";
@import "components/vehicle-logos";
@import "components/img-gallery";
@import "components/cat-list";
@import "components/post-header";
@import "components/post-feed";
@import "components/product-hero";
@import "components/gallery-counter";
@import "components/video-gallery";
@import "components/key-features";
@import "components/specification";
@import "components/text-hero";
@import "components/testimonials";
@import "components/staff-list";
@import "components/vacancies";
@import "components/dealer";
@import "components/manuals";
@import "components/head-office";
@import "components/form-fields";
@import "components/portal";

@import "plugins/tiny-slider";
@import "plugins/theme-my-login";
@import "plugins/woocommerce";
@import "plugins/_lite-yt-embed";