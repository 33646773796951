.gallery-counter {
  flex-shrink: 0;
  padding: 0.5rem;

  font-size: $font-size-xxs;

  border-radius: $border-radius-sm;
}

// Variants 

.bg-grey-light {
  
  .gallery-counter {
    background-color: white;
  }

}

.bg-white {

  .gallery-counter {
    background-color: $color-grey-light;
  }
  
}

.product-gallery-outer {

  .gallery-counter {
    background-color: $color-grey-light;
  }

}