// Titles

.title {
  font-family: $interstate;
  line-height: $line-height-sm;

    a {
      color: inherit;
    }

}

.title--xl {
  font-size: calc($font-size-xl * $font-mobile-scale);
  letter-spacing: $kerning-md;

    @include mq(screen-lg) {
      font-size: $font-size-xl;
    }

}

.title--lg {
  font-size: calc($font-size-lg * $font-mobile-scale);
  letter-spacing: $kerning-sm;

    @include mq(screen-lg) {
      font-size: $font-size-lg;
    }
}

.title--md {
  font-size: calc($font-size-md * $font-mobile-scale);
  letter-spacing: $kerning-sm;

    @include mq(screen-lg) {
      font-size: $font-size-md;
    }

}

.title--sm {
  font-size: $font-size-xs;
  letter-spacing: $kerning-sm;
}

// Text

.supporting-text {
  font-size: calc($font-size-sm * $font-mobile-scale);

    @include mq(screen-lg) {
      font-size: $font-size-sm;
    }

    a {
      color: black;
    }

    a:hover {
      color: $color-orange-dark;
    }

}

.feed-text {
  font-size: $font-size-xxs;
}

// Colours

.text-black {
  color: black;
}

.text-grey-dark {
  color: $color-grey-dark;
}

.title.text-grey-dark a:hover,
.title.text-white a:hover {
  color: $color-orange-dark;
}

.text-white {
  color: white;
}

.text-orange-dark {
  color: $color-orange-dark;
}

.title.text-orange-dark a:hover {
  color: black;
}