.pg-end-ctas {
  display: grid;
  grid-gap: $padding-md;
  margin: 0 $padding-md;

    @include mq(screen-sm) {
      grid-template-columns: 1fr 1fr;
    }

}

.pg-end-cta {
  position: relative;

  border-radius: $border-radius-lg;
  overflow: hidden;
}

.pg-end-cta__img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;

  width: 100%;
  height: 100%;

  object-fit: cover;
}

.pg-end-cta__content {
  position: relative;
  z-index: 2;

  display: block;
  height: 100%;
  padding: $padding-lg $padding-md;

  text-decoration: none;

  transition: $transition-sm;

    @include mq(screen-xxs) {
      padding: $padding-lg 10rem $padding-lg $padding-md;
    }

    @include mq(screen-lg) {
      padding: $padding-lg 20rem $padding-lg $padding-lg;
    }

    @include mq(screen-xl) {
      padding: $padding-xl 20rem $padding-xl $padding-lg;
    }

}

.pg-end-cta__link {
  display: grid;
  place-items: center;

  width: 5rem;
  height: 5rem;
  margin: $padding-md 0 0;

  border-radius: 100%;

    @include mq(screen-xxs) {
      position: absolute;
      top: 50%;
      right: $padding-md;
      transform: translateY(-50%);
      z-index: 1;

      margin: 0;
    }

    @include mq(screen-lg) {
      right: $padding-lg;
    }

    svg {
      width: 50%;
      height: auto;
    }

}

// Colour variants

.pg-end-cta:first-child {

  .pg-end-cta__content {
    background-color: rgba($color-orange-dark, 0.8);

      &:hover {
        background-color: rgba($color-orange-dark, 0.9);
      }

  }

  .title {
    color: white;
  }

  .supporting-text {
    color: $color-grey-dark;
  }

  .pg-end-cta__link {
    background-color: $color-grey-dark;

      svg {
        fill: white;
      }

  }

}

.pg-end-cta:last-child {

  .pg-end-cta__content {
    background-color: rgba($color-grey-dark, 0.8);

      &:hover {
        background-color: rgba($color-grey-dark, 0.9);
      }

  }

  .title {
    color: $color-orange-dark;
  }

  .supporting-text {
    color: white;
  }

  .pg-end-cta__link {
    background-color: white;

      svg {
        fill: $color-grey-dark;
      }
      
  }

}