html,
body {
	border: none;
	margin: 0;
	padding: 0;
}

body {
	position: relative;
	
	font-family: $soleto;
	font-style: normal;
	font-size: calc($font-size-xs * $font-mobile-scale);
	color: $color-grey-mid;

	background-color: white;

		@include mq(screen-lg) {
			font-size: $font-size-xs;
		}
		
}

[id] {
  scroll-margin-top: 2rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: normal;
	line-height: $line-height-sm;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
address,
big,
cite,
code,
em,
font,
img,
small,
strike,
sub,
sup,
li,
ol,
ul,
fieldset,
form,
label,
legend,
button,
table,
caption,
tr,
th,
td {
	border: none;
	font-weight: inherit;
	line-height: inherit;
	margin: 0;
	padding: 0;
	text-align: inherit;
}

p {
	line-height: $line-height-md;
}

strong {
	font-weight: normal;
}

blockquote::before,
blockquote::after {
	content: "";
}

html {
	font-size: 62.5%; /* 1rem = 10px */
	scroll-behavior: smooth;
}

@media (prefers-reduced-motion: reduce) {
	html {
		scroll-behavior: auto;
	}

	* {
		animation-duration: 0s !important;
		transition-duration: 0s !important;
  }

}

*,
*::before,
*::after {
	box-sizing: inherit;
	-webkit-font-smoothing: antialiased;
	word-break: break-word;
	word-wrap: break-word;
}


input[type="text"],
input[type="password"],
input[type="email"],
input[type="url"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="datetime"],
input[type="datetime-local"],
input[type="week"],
input[type="number"],
input[type="search"],
input[type="tel"],
input[type="color"],
input[type="submit"],
textarea,
button {
	font-family: inherit;
	-webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
	box-shadow: none;
}

select {
	font-size: inherit;
}

button, input[type="submit"] {
	cursor: pointer;
}

.no-scroll {
  overflow: hidden;
}

* {
	box-sizing: border-box;
}

.screen-reader-text {
	display: none;
}