.dealers-header {

  @include mq(screen-xs) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .title {
    margin: 0 0 $padding-sm;

      @include mq(screen-xs) {
        margin: 0;
      }

  }

}

.dealer-group + .dealer-group {
  padding: $padding-md 0 0;
  margin: $padding-md 0 0;

  border-top: 1px solid white;

    @include mq(screen-sm) {
      padding: $padding-lg 0 0;
      margin: $padding-lg 0 0;
    }

}

.dealer-feed {
  display: grid;
  grid-gap: $padding-md;
  list-style-type: none;

    @include mq(screen-xs) {
      grid-template-columns: 1fr 1fr;
    }

}

.dealer {
  padding: $padding-md;

  background-color: white;
  border-radius: $border-radius-md;
  box-shadow: $box-shadow;
}

.dealer__header {
  margin: 0 0 $padding-md;

    @include mq(screen-sm) {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

}

.dealer__logo {
  display: block;
  width: 12rem;
  height: 6rem;
  margin: 0 0 $padding-md;

  object-fit: contain;
  object-position: left center;

    @include mq(screen-sm) {
      margin: 0;
    }

}

.dealer__locations {
  display: flex;
  gap: 0.5rem;
  list-style-type: none;

  @include flexbox-gap-support(0.5);
}

.dealer__location__flag {
  display: block;
  width: 4rem;
  height: auto;
}

.dealer__address {
  margin: 0 0 $padding-md;

  a {
    color: black;
  }

  a:hover {
    color: $color-orange-dark;
  }

}

