.cat-list {
  display: flex;
  gap: 1rem;
  list-style-type: none;

  @include flexbox-gap-support(1);
} 

.cat-list__cat {
  font-size: $font-size-xxs;
  color: $color-grey-dark;
}

.cat-list__cat:before {
  content: "";

  display: inline-block;
  width: 8px;
  height: 8px;
  margin: 0 0.5rem 0 0;
  
  border-radius: 100%;
  background-color: $color-orange-dark;
}