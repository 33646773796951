.blog-header {

    @include mq(screen-sm) {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .title {
      margin: 0 0 $padding-sm;

      @include mq(screen-sm) {
        margin: 0;
      }

    }

}

// Latest post feed

.latest-post-feed {
  display: grid;
  grid-gap: $padding-md;

  list-style-type: none;

    @include mq(screen-sm) {
      grid-template-columns: repeat(3, 1fr);
    }
    
}

.latest-post-feed__item {
  
  @include mq(screen-sm) {
    position: relative;

    border-radius: $border-radius-md;
    background-color: rgba(white, 0.1);

    overflow: hidden;
  }

  .cat-list__cat,
  .post-meta__date {
    color: white;
  }

  .cat-list__cat {
    
    @include mq(screen-sm) {
      position: absolute;
      bottom: $padding-lg;
      left: $padding-md;
    }

  }

  .post-feed__content {

    @include mq(screen-sm) {
      padding: $padding-md $padding-sm 12rem;
    }

    @include mq(screen-lg) {
      padding: $padding-md $padding-md 12rem;
    }

  }

}

.latest-post-feed__item + .latest-post-feed__item {
  padding: $padding-md 0 0;

  border-top: 1px solid $color-grey-mid;

    @include mq(screen-sm) {
      padding: 0;

      border: none;
    }

}

.latest-post-feed__img-outer {
  display: none;

    @include mq(screen-sm) {
      display: block;

      @include aspect-ratio(16, 9);
    }

}

.latest-post-feed__img {
  @include object-fit;
}

// Mini post feed

.post-feed {
  display: grid;
  grid-gap: $padding-md;

  list-style-type: none;

    @include mq(screen-sm) {
      grid-template-columns: 1fr 1fr;
    }

    @include mq(screen-lg) {
      grid-gap: $padding-lg;
    }

}

.post-feed__item {
  
  @include mq(screen-sm) {
    display: flex;
  }

}

.post-feed__item + .post-feed__item {
  padding: $padding-md 0 0;

  border-top: 1px solid $color-grey-light;

    @include mq(screen-sm) {
      padding: 0;

      border: none;
    }

}

.post-feed__img {
  display: none;
  
    @include mq(screen-sm) {
      display: block;
      width: 10rem;
      height: 10rem;
      margin: 0 $padding-md 0 0;

      object-fit: cover;
      object-position: center;

      border-radius: $border-radius-sm;
      overflow: hidden;
    }

}

.post-feed .post-feed__content {

  @include mq(screen-sm) {
    position: relative;
    flex: 1;
    padding: 0.5rem 0 6rem;
  }

  .cat-list__cat {
    
    @include mq(screen-sm) {
      position: absolute;
      bottom: 0;
      left: 0
    }

  }

}

.post-feed--archive .post-feed__content {
  
  @include mq(screen-sm) {
    padding: 0.5rem 0 0;
  }

}