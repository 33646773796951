// GENERAL STUFF

.woocommerce-notices-wrapper {
  width: 100%;
}

.woocommerce-message {
  display: flex;
  justify-content: space-between;

  padding: $padding-sm;
  margin: 0 0 $padding-md;

  border-radius: $border-radius-sm;
  background-color: $color-orange-dark;

  color: white;

    a {
      @extend .btn-sm;
      @extend .btn--grey-dark;

      flex-shrink: 0;
      order: 2;
      margin: 0 0 0 $padding-md;
    }

}

// PRODUCT LIST PAGE
// archive-product.php

.product-grid-filters {

  @include mq(screen-xs) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }

  .woocommerce-result-count {
    font-size: $font-size-xxs;
    color: $color-grey-dark;

      &:before {
        content: "";

        display: inline-block;
        width: 10px;
        height: 10px;
        margin: 0 0.5rem 0 0;
        
        border-radius: 100%;
        background-color: $color-orange-dark;
      }

  }

  .woocommerce-ordering select {
    display: block;
    width: 100%;
    height: 3rem;
    padding: 0 0.5rem;

    border: 2px solid $color-grey-mid;
    border-radius: $border-radius-sm;

    font-size: $font-size-xxs;
    font-family: $soleto;

      &:focus {
        outline: none;
        border-color: $color-orange-dark;
      }

  }

}

.products.columns-4 {
  display: grid;
  grid-gap: $padding-sm;
  margin: 0 0 $padding-lg;

  list-style-type: none;
  
    @include mq(screen-xs) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include mq(screen-sm) {
      grid-template-columns: repeat(3, 1fr);
    }

    @include mq(screen-lg) {
      grid-template-columns: repeat(4, 1fr);
    }
    
}

.woocommerce-loop-product__link {
  display: block;
  height: 100%;
  padding: $padding-md $padding-md 10rem;

  border-radius: $border-radius-md;
  box-shadow: $box-shadow;
  background-color: white;

  text-decoration: none;

    img {
      display: block;
      width: 100%;
      height: 20rem;
      object-fit: contain;
      object-position: center center;
      margin: 0 0 $padding-md;

        @include mq(screen-md) {
          height: 25rem;
        }

    }

    h2 {
      @extend .title;
      @extend .title--sm;
      @extend .text-grey-dark;

      margin: 0 0 1rem;

      text-decoration: underline;
    }

    .price {
      font-family: $interstate;
      color: $color-grey-mid;
    }

    &:hover {
      
      h2 {
        color: $color-orange-dark;
      }

    }

}

.product {
  position: relative;
  
    .add_to_cart_button {
      position: absolute;
      left: $padding-md;
      bottom: $padding-md;
      z-index: 1;

      @extend .btn-sm;
      @extend .btn--orange;
    }

}

.woocommerce-pagination {

  .page-numbers {
    font-family: $interstate;
    font-size: $font-size-xs;
  }
  
  ul.page-numbers {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    list-style-type: none;

      li {
        margin: 0 1rem;
      }

      li:not(:first-child):not(:last-child) {
        display: none;

          @include mq(screen-sm) {
            display: block;
          }

      }

  }
  
  a.page-numbers {
    display: block;
    padding: 1rem;

    color: $color-grey-dark;

      &:hover {
        color: $color-orange-dark;
      }

  }

  span.page-numbers.current {
    color: $color-orange-dark;
  }


}

// PRODUCT PAGE
// single-product.php
// content-single-product.php

.main-content--portal {
  
  .product-hero-outer {
    padding: $padding-lg 0;

      @include mq(screen-sm) {
        padding: $padding-lg 0 $padding-xl;
      }

  }

  .product-hero-outer:before {
    top: 0;
  }

}

.portal-gallery-outer {
  
  @include mq(screen-sm) {
    order: 1;
  }

}

.portal-gallery__img-outer {
  width: 100%;
  
    @include mq(screen-sm) {
      @include aspect-ratio(3, 2);
    }

}

.portal-gallery__img {
  display: block;
  width: 100%;
  height: 30rem;

  object-fit: contain;

    @include mq(screen-sm) {
      height: 40rem;
    }

}

.summary {
  
  > h2 {
    @extend .title;
    @extend .title--lg;
    @extend .text-grey-dark
  }

  .price,
  #product_total_price {
    font-size: $font-size-sm;

    @extend .product-id;
  }


}

.product-info { 
  
  .price {
    @extend .mb-md;
  }

  .cart {
    @extend .mt-md;
    @extend .mb-lg;

    padding: $padding-md;

    background-color: white;
    border-radius: $border-radius-md;
    box-shadow: $box-shadow;
  }

  .product_meta {

    > span {
      display: block;
    }

    .sku_wrapper {
      margin: 0 0 $padding-md;

      @extend .product-id;
    }
  
  }

  .posted_in a {
    @extend .btn-xs;
    @extend .btn--grey-dark;
  }


}

.add-to-cart-actions {
  display: flex;

    .quantity {
      width: 15rem;
      margin: 0 $padding-sm 0 0;
    }

    .qty {
      display: block;
      width: 100%;
      height: 4rem;
      padding: 0 1rem;

      border: 2px solid $color-grey-mid;
      border-radius: $border-radius-sm;

      font-size: $font-size-xs;
      font-family: $soleto;

        &:focus {
          outline: none;
          border-color: $color-orange-dark;
        }

    }

    button {
      @extend .btn-lg;
      @extend .btn--orange;
    }

}

.product .related > h2 {
  @extend .title;
  @extend .title--lg;
  @extend .text-grey-dark;
  @extend .mb-md;
}

// CART PAGE
// page-shop.php
// woocommerce/cart/cart.php

.cart-cols {
  display: grid;
  grid-gap: $padding-lg;
    
    @include mq(screen-md) {
      grid-template-columns: 2.5fr 1fr;
    }

}

.woocommerce-cart-form > table,
.woocommerce-orders-table {
  width: 100%;

  border-top: 2px solid $color-grey-dark;

    @include mq(screen-xs) {
      border: none;
    }

    thead {
      display: none;

        @include mq(screen-xs) {
          display: table-header-group;
        }

    }

    th {
      padding: 0 0 0.5rem;
      font-family: $interstate;
      font-size: $font-size-xs;
      color: $color-grey-dark;
      letter-spacing: $kerning-sm;

      border-bottom: 2px solid $color-grey-dark;
    }

    td {
      display: block;

      padding: 1rem 0;

      border-bottom: 1px solid $color-grey-light;

        @include mq(screen-xs) {
          display: table-cell;
          padding: 1rem 1rem 1rem 0;
        }

    }

    td:last-child {
      
      @include mq(screen-xs) {
        text-align: right;
        padding: 1rem 0;
      }

    }

    td.product-name a {
      color: $color-grey-dark;
    }

    td.product-name a:hover {
      color: $color-orange-dark;
    }

    .qty {
      display: block;
      width: 10rem;
      height: 4rem;
      padding: 0 1rem;

      border: 2px solid $color-grey-mid;
      border-radius: $border-radius-sm;

      font-size: $font-size-xs;
      font-family: $soleto;

        &:focus {
          outline: none;
          border-color: $color-orange-dark;
        }

    }

    .cart-mob-label {
      font-family: $interstate;
      letter-spacing: $kerning-sm;

        @include mq(screen-xs) {
          display: none;
        }

    }

}

.cart-collaterals {
  padding: $padding-md;

  background-color: white;
  border-radius: $border-radius-md;
  box-shadow: $box-shadow;

    h2 {
      @extend .title;
      @extend .title--sm;
      @extend .text-grey-dark;
      @extend .mb-md;
    }

    table {
      margin: 0 0 $padding-md;

      font-size: $font-size-xxs;
    }

    table th {
      width: 30%;
      padding: 0.5rem 0.5rem 0.5rem 0;

      font-family: $interstate;
      color: $color-grey-dark;
      letter-spacing: $kerning-sm;

      vertical-align: top;
    }

    table td {
      width: 70%;
      padding: 0.5rem 0;

      vertical-align: top;
    }

    .woocommerce-shipping-destination {
      margin: 0 0 1rem;
    }

    .woocommerce-shipping-calculator a {
      color: $color-grey-dark;
    }

    .woocommerce-shipping-calculator a:hover {
      color: black;
    }

    .checkout-button {
      @extend .btn-lg;
      @extend .btn--orange;
    }


}

.woocommerce-shipping-methods {
  margin: 0 0 1rem;

  list-style-type: none;

    li + li {
      margin: 0.25rem 0 0;
    }

}

.cart-subtotal .woocommerce-Price-amount,
.order-total .woocommerce-Price-amount {
  color: $color-grey-dark;
}

// Checkout

#customer_details {
  display: none;
}

.woocommerce-checkout > h3 {
  @extend .title;
  @extend .title--md;
  @extend .text-grey-dark;
  @extend .mb-md;
}

.woocommerce-checkout-review-order > table,
.woocommerce-table--order-details {
  width: 100%;

  border-top: 2px solid $color-grey-dark;

  @extend .mb-md;

    @include mq(screen-xs) {
      border: none;
    }

    thead {
      display: none;

        @include mq(screen-xs) {
          display: table-header-group;
        }

    }

    thead th {
      padding: 0 0 0.5rem;
      font-family: $interstate;
      font-size: $font-size-xs;
      color: $color-grey-dark;
      letter-spacing: $kerning-sm;

      border-bottom: 2px solid $color-grey-dark;
    }

    tfoot tr:first-child th, 
    tfoot tr:first-child td {
      border-top: 2px solid $color-grey-light;
    }

    tfoot th {
      font-family: $interstate;
      font-size: $font-size-xs;
      color: $color-grey-dark;
      letter-spacing: $kerning-sm;
    }

    th, td {
      vertical-align: top;
    }

    td, th {
      padding: 1rem 1rem 1rem 0;

      border-bottom: 1px solid $color-grey-light;
    }

    td:last-child {
      
      @include mq(screen-xs) {
        text-align: right;
        padding: 1rem 0;
      }

    }

    td.product-name a {
      color: $color-grey-dark;
    }

    td.product-name a:hover {
      color: $color-orange-dark;
    }


}

.woocommerce-checkout-payment {
  padding: $padding-md;

  background-color: white;
  border-radius: $border-radius-md;
  box-shadow: $box-shadow;

    button {
      @extend .btn-lg;
      @extend .btn--orange;
    }

}

.wc_payment_methods {
  list-style-type: none;

    .payment_box {
      margin: $padding-sm 0 0;

        label {
          @extend .form-row__label;
        }

    }

}

.place-order {
  @extend .mt-md;
}

.woocommerce-privacy-policy-text {
  font-size: $font-size-xxs;
  line-height: $line-height-md;

  @extend .mb-md;

    a {
      color: $color-grey-dark;
    }

    a:hover {
      color: black;
    }

}

// MY ACCOUNT
// woocommerce/myaccount/my-account.php

.my-account {
  display: grid;
  grid-gap: $padding-md;

    @include mq(screen-sm) {
      grid-template-columns: 1fr 3fr;
    }

}

.woocommerce-customer-details,
.woocommerce-Message {
  @extend .std-content;
}

.woocommerce-MyAccount-content {
  
  > h2 {
    margin: 0 0 $padding-md;
    font-family: $interstate;
		line-height: $line-height-sm;
		color: $color-grey-dark;
		letter-spacing: $kerning-md;
  }

  > p {

    a {
			color: black;
			text-decoration: underline;
		}

		a:hover {
			color: $color-orange-dark;
		}

		& > strong {
			color: black
		}

		& > em {
			font-style: italic;
		}

    line-height: $line-height-md;
  }

}

// Navigation
// woocommerce/myaccount/navigation.php

.woocommerce-MyAccount-navigation {
  
  ul {
    font-size: $font-size-xs;
    list-style-type: none;
  }

  li + li {
    margin: 1rem 0 0;
  }

  li.is-active a {
    color: $color-orange-dark;
    text-decoration: underline;
  }

  a {
    color: $color-grey-dark;
    text-decoration: none;

      &:hover {
        color: $color-orange-dark;
      }

  }

}

// Dashboard
// woocommerce/myaccount/dashboard.php

// Orders
// woocommerce/myaccount/orders/php

.woocommerce-orders-table__cell-order-number {

  a {
    color: $color-grey-dark;
  }

  a:hover {
    color: black;
  }

}

.woocommerce-orders-table__cell-order-actions {
  a {
    margin: 0.5rem;

    @extend .btn-xs;
    @extend .btn--orange;
  }

}

.woocommerce-order-details__title {
  @extend .title;
  @extend .title--md;
  @extend .text-grey-dark;
  @extend .mb-sm;
}

// Addresses
// woocommerce/myaccount/my-address.php

.woocommerce-Addresses {
  margin: $padding-md 0 0;

  h2, h3 {
    margin: 0 0 $padding-sm;
    font-family: $interstate;
		line-height: $line-height-sm;
		color: $color-grey-dark;
		letter-spacing: $kerning-md;
  }

  p {

    a {
			color: black;
			text-decoration: underline;
		}

		a:hover {
			color: $color-orange-dark;
		}

		& > strong {
			color: black
		}

		& > em {
			font-style: italic;
		}

    line-height: $line-height-md;
  }

  address {
    font-style: normal;
  }

}

.woocommerce-Address + .woocommerce-Address {
  margin: $padding-md 0 0;
}

.woocommerce-Address-title a {
  margin: 0 0 $padding-md;

  font-family: $soleto;

  @extend .btn-xs;
  @extend .btn--orange;
}

// Account details

.woocommerce-form-row {

  label {
    @extend .form-row__label;
  }

  .form-row__helper {
    display: block;
    margin: 1rem 0 0;

    font-size: $font-size-xxs;

      em {
        font-style: normal;
      }

  }

}