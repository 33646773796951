.product-hero-outer {
  position: relative;
  padding: $padding-lg 0 0;

  background-color: white;
  box-shadow: $box-shadow;

  overflow: hidden;

    @include mq(screen-sm) {
      padding: $padding-xl 0 0;
    }

    .inner {
      position: relative;
      z-index: 2;
    }

    &:before {
      content: "";
  
      position: absolute;
      top: -10vw;
      right: -40vw;
      z-index: 1;
  
      width: 60vw;
      padding-bottom: 60vw;
  
      background: $gradient-orange-h;
      border-radius: 100%;
    }

}

.product-hero {
  display: grid;
  grid-gap: $padding-lg;

  @include mq(screen-sm) {
    grid-template-columns: 1fr 1fr;
  }

  @include mq(screen-lg) {
    grid-gap: $padding-xl;
  }

}

.product-info {
  
  @include mq(screen-sm) {
    order: 2;
  }

}

.product-info__related {
  @extend .product-card__related;
}

.product-info__creds {
  display: flex;
  flex-wrap: wrap;
  gap: $padding-sm;
  list-style-type: none;

  @include flexbox-gap-support(1);
}

.product-info__cred {
  display: block;
  width: auto;
  height: 4rem;

  object-fit: contain;
}

// Product gallery

.product-gallery-outer {
  display: flex;
  flex-direction: column;
  align-items: center;

  position: relative;

    @include mq(screen-sm) {
      order: 1;
    }

}

.product-gallery-control {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;

  display: grid;
  place-items: center;
  width: 3rem;
  height: 3rem;

  border-radius: 100%;
  background-color: $color-orange-dark;

    svg {
      width: 1.5rem;
      height: 1.5rem;

      fill: white;
    }

    &:hover {
      background-color: $color-grey-dark;
    }

}

.product-gallery-control--prev {
  left: $padding-md;

    svg {
      transform: rotate(-180deg);
    }

}

.product-gallery-control--next {
  right: $padding-md;
}

.product-gallery__img-outer {
  width: 100%;
  @include aspect-ratio(3, 2);
}

.product-gallery__img {
  display: block;
  width: 100%;
  height: 100%;

  object-fit: contain;
}

// Product nav

.product-nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: $padding-md 0;

    @include mq(screen-lg) {
      padding: $padding-lg 0;
    }

    .btn-group {
      justify-content: center;
      flex-wrap: wrap;  
    }

}