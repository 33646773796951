.portal-header {
  padding: $padding-md 0;
  margin: 0 0 $padding-lg;
  border-bottom: 1px solid $color-grey-light;
  
    @include mq(screen-sm) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: $padding-lg 0 $padding-md;
    }

}

.portal-header__logo {
  display: block;
  width: 12rem;
  height: 6rem;

  object-fit: contain;
  object-position: right center;
}

.woocommerce img.portal-header__logo,
.woocommerce-page img.portal-header__logo {
  height: 6rem;
}

.portal-dashboard {
  display: flex;
  gap: $padding-md;
  justify-content: center;
  flex-wrap: wrap;

  list-style-type: none;

    li {
      
      @include mq(screen-xs) {
        width: calc(50% - $padding-sm);
      }

      @include mq(screen-sm) {
        width: calc(33.333% - $padding-sm);
      }

    }

}

.portal-dashboard__link {
  display: grid;
  grid-gap: $padding-lg;
  justify-items: center;
  padding: $padding-md;

  font-family: $interstate;
  font-size: $font-size-xs;
  letter-spacing: $kerning-md;
  color: $color-grey-dark;
  text-decoration: none;

  background-color: white;
  border-radius: $border-radius-md;
  box-shadow: $box-shadow;
  transition: $transition-sm;

    &:hover {
      box-shadow: $box-shadow-hover;
    }

    img {
      display: block;
      width: 50%;
      height: auto;
    }

}

// File list

.file-list {
  list-style-type: none;
    
    li {
      display: flex;
      justify-content: space-between;
    }

    li + li {
      margin: $padding-sm 0 0;
      padding: $padding-sm 0 0;

      border-top: 1px solid $color-grey-light;
    }

}

.file-list__label {
  margin: 0.5rem 0 0;
}
