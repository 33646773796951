// Text alignment

.align-center {
  text-align: center;
}

// Background color

.bg-white {
  background-color: white;
}

.bg-grey-dark {
  background-color: $color-grey-dark;
}

.bg-grey-mid {
  background-color: $color-grey-mid;
}

.bg-grey-light {
  background-color: $color-grey-light;
}

.bg-black {
  background-color: black;
}

.bg-orange-dark {
  background-color: $color-orange-dark;
}

.bg-orange-light {
  background-color: $color-orange-light;
}

.bg-orange-gradient {
  background: $gradient-orange-h;
}

// Asset rendering

.video-embed {
  @include aspect-ratio(16, 9);
}

.video-embed iframe {
  width: 100%;
  height: 100%;
}

.flex-img {
  @include flex-img;
}

// Borders