.staff-list {
  list-style-type: none;
}

.staff {
  display: grid;
  grid-gap: $padding-md;

    @include mq(screen-xs) {
      grid-template-columns: 1fr 3fr;
    }

    @include mq(screen-lg) {
      grid-gap: $padding-lg;
    }


}

.staff + .staff {
  padding: $padding-md 0 0;
  margin: $padding-md 0 0;

  border-top: 1px solid $color-grey-mid;

    @include mq(screen-lg) {
      padding: $padding-lg 0 0;
      margin: $padding-lg 0 0;
    }

}

.staff-photo {
  display: block;
  width: 100%;
  height: auto;

  border-radius: $border-radius-sm;
}