// Email signup

.email-signup {
  
}

.email-signup__form-fields {
  display: grid;
  grid-gap: $padding-sm;
  
    @include mq(screen-sm) {
      grid-template-columns: repeat(3, 1fr);
    }

}

.email-signup__form-field {
  
  label {
    display: block;
    width: 100%;
    margin: 0 0 1rem;

    font-size: $font-size-xs;
    color: $color-grey-dark;
  }

  input[type="text"],
  input[type="email"] {
    display: block;
    width: 100%;
    height: 5rem;
    padding: 0 1rem;

    border: none;
    border-radius: $border-radius-sm;

    font-size: $font-size-xs;
    font-family: $soleto;

      &:focus {
        outline: none;
        border: 2px solid $color-grey-dark;
      }

  }

}

.email-signup__form-gdpr {
  position: relative;
  padding: 0 0 0 2.5rem;

  input {
    position: absolute;
    top: 0.25rem;
    left: 0;
    z-index: 1;
  }
  
  label {
    font-size: $font-size-xxs;
    line-height: $line-height-md;
    color: $color-grey-dark;
  }

}

// MailChimp validation

div.mce_inline_error,
div#mce-error-response,
div#mce-success-response {
  margin: 1rem 0 0;
  padding: 1rem;

  border-radius: $border-radius-sm;

  font-size: $font-size-xxs;
  color: white;
}

div.mce_inline_error,
div#mce-error-response {
  background-color: $color-error;
}

div#mce-success-response {
  background-color: $color-success;
}

// Header

.pg-footer-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;

  padding: 0 0 $padding-md;
  margin: 0 0 $padding-md;

  border-bottom: 1px solid $color-grey-mid;

    @include mq(screen-xs) {
      flex-direction: row;
      justify-content: space-between;
    }

    @include mq(screen-sm) {
      padding: 0 0 $padding-lg;
      margin: 0 0 $padding-lg;
    }

}

.pg-footer__logo {
  display: block;
  height: 2.5rem;
  margin: 0 0 $padding-md;

    @include mq(screen-xs) {
      margin: 0;
    }

    @include mq(screen-lg) {
      height: 3rem;
    }

    svg {
      width: auto;
      height: 100%;
    }

}

.social-list {
  display: flex;
  align-items: center;
  margin: 0 0 $padding-md;

  list-style-type: none;

    @include mq(screen-xs) {
      margin: 0;
    }

}

.social-list__link {
  display: block;
  padding: 1rem;

    svg {
      width: 2rem;
      height: 2rem;

      fill: white;
    }

}

.halma {
  width: 15rem;
}

.halma-logo {
  display: block;
  width: 100%;
  height: auto;
}

// Navigation

.pg-footer-navs {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 0 0 $padding-md;
  margin: 0 0 $padding-md;

  border-bottom: 1px solid $color-grey-mid;

    @include mq(screen-xs) {
      flex-direction: row;
      align-items: flex-start;
    }

    @include mq(screen-sm) {
      padding: 0 0 $padding-lg;
      margin: 0 0 $padding-lg;
    }

}

.pg-footer-nav {
  text-align: center;

    @include mq(screen-xs) {
      text-align: left;
    }

}

.pg-footer-nav + .pg-footer-nav {
  margin: $padding-md 0 0;

    @include mq(screen-xs) {
      margin: 0 0 0 $padding-md;
    }

    @include mq(screen-sm) {
      margin: 0 0 0 $padding-lg;
    }

    @include mq(screen-lg) {
      margin: 0 0 0 $padding-xl;
    }

}

.pg-footer-nav__list {
  list-style-type: none;

    a {
      color: white;
      text-decoration: none;

        &:hover {
          color: $color-orange-dark;
        }

    }

}

// Terms

.pg-footer-terms {
  text-align: center;

    @include mq(screen-xs) {
      text-align: left;
    }
    
}

.pg-footer-terms__list {
  list-style-type: none;

    @include mq(screen-xs) {
      display: flex;
    }

    li + li {
      @include mq(screen-xs) {
        margin: 0 0 0 $padding-md;
      }
    }

    a {
      color: white;
      text-decoration: none;

        &:hover {
          color: $color-orange-dark;
        }

    }

}

.copyright {
  color: white;
}




