// Brand colors

// Neutral

$color-grey-dark: #1d242b;
$color-grey-mid: #76868b;
$color-grey-light: #f5f5f5;

// Brand palette

$color-orange-dark: #ef7d43;
$color-orange-light: #fab500;
$gradient-orange-h: linear-gradient(90deg, rgba($color-orange-light,1) 0%, rgba($color-orange-dark,1) 100%);
$gradient-orange-v: linear-gradient(180deg, rgba($color-orange-light,1) 0%, rgba($color-orange-dark,1) 100%);

// Fades

$fade-orange-15: rgba($color-orange-dark, 0.15);

// Validation

$color-error: #DB0000;
$color-success: #55a630;

// Typography

$soleto: soleto, Arial, sans-serif;
$interstate: interstate, Arial Bold, sans-serif;

$kerning-md: -0.15rem;
$kerning-sm: -0.1rem;

$line-height-md: 1.5;
$line-height-sm: 1.2;

$font-size-xl: 5rem;
$font-size-lg: 3.6rem;
$font-size-md: 2.4rem;
$font-size-sm: 2.1rem;
$font-size-xs: 1.8rem;
$font-size-xxs: 1.4rem;

$font-mobile-scale: 0.75;

// UI styles

$padding-global: 5rem;

$padding-xxl: calc($padding-global*3);
$padding-xl: calc($padding-global*2);
$padding-lg: $padding-global;
$padding-md: calc($padding-global/2);
$padding-sm: calc($padding-global/3);

// Box shadow

$box-shadow: 0 4px 6px rgba(0,0,0,0.1);
$box-shadow-hover: 0 4px 6px rgba(0,0,0,0.2);

// Border radius

$border-radius-lg: 2rem;
$border-radius-md: 1rem;
$border-radius-sm: 0.5rem;

// Transitions

$transition-sm: 0.1s all ease-out;