.form-row + .form-row {
  margin: $padding-md 0 0;
}

.form-row__label {
  display: block;
  width: 100%;
  margin: 0 0 1rem;

  color: $color-grey-dark;
  line-height: $line-height-md;
}

// Single line field styles

.form-row input[type="text"],
.form-row input[type="number"],
.form-row input[type="url"],
.form-row input[type="password"],
.form-row input[type="email"],
.form-row input[type="tel"],
.form-row input[type="date"],
.form-row select {
  display: block;
  width: 100%;
  height: 6rem;
  padding: 0 1rem;

  border: 2px solid $color-grey-mid;
  border-radius: $border-radius-sm;

  font-size: $font-size-xs;
  font-family: $soleto;

    &:focus {
      outline: none;
      border-color: $color-orange-dark;
    }

}

.form-row input[type="date"] {
  text-transform: uppercase;
}

// Textarea field styles

.form-row textarea {
  display: block;
  width: 100%;
  height: 30rem;
  padding: 1rem;

  border: 2px solid $color-grey-mid;
  border-radius: $border-radius-sm;

  font-size: $font-size-xs;
  font-family: $soleto;

    &:focus {
      outline: none;
      border-color: $color-orange-dark;
    }

}

// GDPR

.form-row__gdpr {
  padding: $padding-md;
  border-radius: $border-radius-md;
  background-color: white;
  box-shadow: $box-shadow;

  font-size: $font-size-xxs;

    a {
      color: $color-grey-dark;
    }

    a:hover {
      color: black;
    }

}

// ----- CF7 ------ //

.screen-reader-response {
  display: none;
}

// Validation

.wpcf7-not-valid {
  margin: 0 0 1rem;
}

span.wpcf7-not-valid {
  display: block;
  margin: 0 0 1rem;
}

.wpcf7-not-valid-tip {
  display: block;
  padding: 1rem;

  background-color: $color-error;
  border-radius: $border-radius-sm;
  color: white;
}

.wpcf7-response-output {
  display: block;
  padding: 1rem;

  border-radius: $border-radius-sm;
  color: white;
}

.wpcf7-form.invalid .wpcf7-response-output {
  margin: $padding-md 0 0;

  background-color: $color-error;
}

.wpcf7-form.sent .wpcf7-response-output {
  margin: $padding-md 0 0;
  
  background-color: $color-success;
}

// Radios

.wpcf7-radio {
  display: block;
}

.wpcf7-list-item {
  display: block;

    input[type="radio"],
    input[type="checkbox"] {
      margin: 0 1rem 0 0;
    }
}

.wpcf7-list-item + .wpcf7-list-item {
  margin: $padding-sm 0 0;
}

// Acceptance

.wpcf7-acceptance label {
  display: flex;
}

.wpcf7-acceptance input[type="checkbox"] {
  margin: 0.5rem 1rem 0 0;
}

.wpcf7-submit {
  @extend .btn-lg;
  @extend .btn--orange;

  border: none;
  cursor: pointer;
}