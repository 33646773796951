.hero {
  position: relative;
  padding: $padding-lg 0;

    @include mq(screen-sm) {
      padding: $padding-xl 0;
    }

    .inner {
      position: relative;
      z-index: 3;
    }

}

.hero--home {
  
  &:before {
    content: "";

    position: absolute;
    top: -35vw;
    right: -15vw;
    z-index: 1;

    width: 60vw;
    padding-bottom: 60vw;

    background: $gradient-orange-h;
    border-radius: 100%;
  }

  &:after {
    content: "";

    position: absolute;
    top: -25vw;
    right: -5vw;
    z-index: 2;

    width: 40vw;
    padding-bottom: 40vw;

    background: white;
    border-radius: 100%;
  }

}

.hero-content {
  position: relative;

    @include mq(screen-xxs) {
      display: flex;
      flex-wrap: wrap;
    }
    
    @include mq(screen-sm) {
      padding: 0 40% 0 0;
    }

    @include mq(screen-md) {
      padding: 0 50% 0 0;
    }

}

.hero-content__text {

  @include mq(screen-xxs) {
    width: 50%;
  }
  
  @include mq(screen-sm) {
    width: 100%;
  }

  h1 {
    position: relative;



  }

}

.hero-content__img-outer {
  width: 60%;
  margin: $padding-md auto;

    @include mq(screen-xxs) {
      width: 50%;
      margin: 0;
    }
    
    @include mq(screen-sm) {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;

      width: 40%;
    }

    @include mq(screen-md) {
      width: 50%;
    }

}

.hero-content__img {
  display: block;
  width: 100%;
  height: auto;

    @include mq(screen-xxs) {
      width: 50%;
      margin: 0 auto;
    }

}

.hero-cta {
  display: grid;
  grid-gap: $padding-md;
  width: 100%;

  padding: $padding-md;
  margin: $padding-md 0 0;

  background-color: white;
  border-radius: $border-radius-md;
  box-shadow: $box-shadow;

    @include mq(screen-lg) {
      margin: $padding-lg 0 0;
    }

}